import sidebarStyle from '_assets/global/jss/components/Sidebar/sidebarStyle';
import logo from '_assets/projectSpecific/img/ec24-logo.svg';
import Button from '_components/global/Buttons/Button';
import AdminNavbarLinks from '_components/projectSpecific/Navbars/AdminNavbarLinks';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import compose from 'recompose/compose';
import {Trans} from '@lingui/macro';
import Badge from '@material-ui/core/Badge';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import DoubleArrow from '@material-ui/icons/DoubleArrow';

let ps;

class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {className, user, headerLinks, links, resizeButton} = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        <Hidden mdUp implementation="css">
          <hr style={{margin: '15px 20px -12px'}} />
        </Hidden>
        {links}
        {resizeButton}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }

  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) ||
        window.location.href.indexOf(routes[i].path) !== -1
      ) {
        return true;
      }
    }
    return false;
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) !== -1 ? 'active' : '';
  };

  createLinks = (routes) => {
    const {classes, color} = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      const itemText =
        classes.itemText +
        ' ' +
        cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        });

      const collapseItemMini = classes.collapseItemMini;
      const itemIcon = classes.itemIcon;

      if (prop.collapse) {
        let st = {};
        st[prop['state']] = !this.state[prop.state];
        const navLinkClassesCollapse =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views),
          });

        const caret = classes.caret;
        let listItem = (
          <ListItem
            key={key}
            className={cx(
              {[classes.item]: prop.icon !== undefined},
              {[classes.collapseItem]: prop.icon === undefined}
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClassesCollapse}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={prop.name}
                secondary={
                  <b
                    className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')}
                  />
                }
                disableTypography={true}
                className={cx(
                  {[itemText]: prop.icon !== undefined},
                  {[collapseItemText]: prop.icon === undefined}
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );

        if (prop.badge) {
          listItem = (
            <Badge
              badgeContent={this.props.Notifications[prop.badge.key]}
              color="secondary"
              className={classes.itemBadge}
            >
              {listItem}
            </Badge>
          );
        }

        if (typeof prop.authorized === 'object' && prop.authorized !== null) {
          return (
            <Can key={key} I={prop.authorized.actions} a={prop.authorized.subject}>
              {listItem}
            </Can>
          );
        }
        return listItem;
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      const navLinkClasses =
        classes.itemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      let listItem = (
        <ListItem
          key={key}
          className={cx(
            {[classes.item]: prop.icon !== undefined},
            {[classes.collapseItem]: prop.icon === undefined}
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              {[navLinkClasses]: prop.icon !== undefined},
              {[innerNavLinkClasses]: prop.icon === undefined}
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={cx(
                {[itemText]: prop.icon !== undefined},
                {[collapseItemText]: prop.icon === undefined}
              )}
            />
          </NavLink>
        </ListItem>
      );

      if (prop.badge) {
        listItem = (
          <Badge
            badgeContent={this.props.Notifications[prop.badge.key]}
            color="secondary"
            className={classes.itemBadge}
          >
            {listItem}
          </Badge>
        );
      }

      if (typeof prop.authorized === 'object' && prop.authorized !== null) {
        return (
          <Can key={key} I={prop.authorized.actions} a={prop.authorized.subject}>
            {listItem}
          </Can>
        );
      }
      return listItem;
    });
  };

  render() {
    const {classes, image, routes, bgColor, miniActive, sidebarMinimize} = this.props;

    let links = <List className={classes.list}>{this.createLinks(routes)}</List>;
    let resizeButton = (
      <Hidden smDown implementation="css">
        <div className={classes.sidebarMinimize}>
          {miniActive ? (
            <Button onClick={sidebarMinimize}>
              <DoubleArrow className={classes.sidebarRightMiniIcon} />
            </Button>
          ) : (
            <Button onClick={sidebarMinimize}>
              <DoubleArrow className={classes.sidebarLeftMiniIcon} />
              <Trans>Minimize sidebar</Trans>
            </Button>
          )}
        </div>
      </Hidden>
    );

    const logoClasses = cx(
      classes.logo,
      {[classes.whiteAfter]: bgColor === 'white'},
      {[classes.minifyLogo]: miniActive}
    );

    let brand = (
      <div className={logoClasses}>
        <img src={logo} alt="logo" />
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={<AdminNavbarLinks />}
              links={links}
              resizeButton={resizeButton}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{backgroundImage: 'url(' + image + ')'}} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            anchor={'left'}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} links={links} resizeButton={resizeButton} />
            {image !== undefined ? (
              <div className={classes.background} style={{backgroundImage: 'url(' + image + ')'}} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'gray',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue', 'gray']),
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose', 'gray']),
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default compose(
  withStyles(sidebarStyle),
  connect((store) => {
    return {
      Notifications: store.Notifications,
    };
  }, null)
)(Sidebar);

import {getOrderableModules, postModuleLicence} from '_actions/projectSpecific/modulesActions';
import {globalPermissionsFormStyle} from '_assets/projectSpecific/jss/components/Forms/globalPermissionsFormStyle';
import Button from '_components/global/Buttons/Button';
import {
  FormCheckbox,
  FormDateField,
  FormSelectField,
  FormTextField,
} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {DATE_FORMAT_DB} from '_config/configConstants';
import {required} from '_helpers/FieldLevelValidation';
import {fireSuccessToast} from '_helpers/functions';
import cx from 'classnames';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const ModuleLicenceForm = ({
  onSubmit,
  isOpen,
  form,
  classes,
  submitting,
  pristine,
  reset,
  formData,
  customerId,
  getOrderableModules,
  postModuleLicence,
}) => {
  const [orderableModules, setOrderableModules] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const prepareData = {
      module_id: formData.module,
      valid_from: formData.immediate ? null : formData.valid_from.format(DATE_FORMAT_DB),
      create_immediately: formData.immediate,
    };
    postModuleLicence(customerId, prepareData).then(() => {
      fireSuccessToast(<Trans>Module licence created</Trans>);
      onSubmit();
    });
  };

  const handleGetOrderableModules = useCallback(() => {
    getOrderableModules(customerId).then((res) => {
      if (res) setOrderableModules(res);
    });
  }, [getOrderableModules, customerId]);

  useEffect(() => {
    if (isOpen) handleGetOrderableModules();
  }, [isOpen, handleGetOrderableModules]);

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer justify="flex-start">
        <GridItem xs={12}>
          <Field
            name={'module'}
            label={<Trans>Module name</Trans>}
            type="select"
            component={FormSelectField}
            options={orderableModules.map((module) => ({label: module.name, value: module.id}))}
            validate={required}
            required
          />
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="immediate"
            component={FormCheckbox}
            label={<Trans>Active immediately</Trans>}
          />
        </GridItem>
        <GridItem xs={12}>
          {formData?.immediate ? (
            <Field
              name="valid_from"
              label={<Trans>Valid from</Trans>}
              component={FormTextField}
              input={{value: 'immediate'}}
              disabled={true}
            />
          ) : (
            <Field
              name="valid_from"
              dateLabel={<Trans>Valid from</Trans>}
              component={FormDateField}
              id="valid_from"
              reduxName="valid_from"
              formName={form}
              disabled={formData?.immediate}
              minDate={moment().add(1, 'day')}
              validate={required}
              required
            />
          )}
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={submitting || pristine}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button color={'primary'} disabled={submitting || pristine} type="submit">
              <Trans>Save</Trans>
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderableModules,
      postModuleLicence,
    },
    dispatch
  );
};

export default compose(
  withStyles(globalPermissionsFormStyle),
  reduxForm({
    enableReinitialize: true,
  }),
  connect((store) => {
    return {
      formData: getFormValues('createModuleLicence')(store),
    };
  }, mapDispatchToProps)
)(ModuleLicenceForm);

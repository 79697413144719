import {
  postStammnummer,
  postTypenscheinnummer,
} from '_actions/projectSpecific/vehicleReferencesActions';
import {vehicleReferencesPageStyle} from '_assets/projectSpecific/jss/views/vehicleReferencesPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import FilterVRForm from '_components/projectSpecific/Forms/FilterVRForm';
import VehicleReferencesForm from '_components/projectSpecific/Forms/VehicleReferencesForm';
import VRStamnummerTable from '_components/projectSpecific/Tables/VRStamnummerTable';
import VRTypenscheinnummerTable from '_components/projectSpecific/Tables/VRTypenscheinnummerTable';
import CommonTabs from '_components/projectSpecific/Tabs/CommonTabs';
import VehicleReferencesTableToolbar from '_components/projectSpecific/Toolbars/VehicleReferencesTableToolbar';
import {fireErrorToast} from '_helpers/functions';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tab from '@material-ui/core/Tab';
import {withStyles} from '@material-ui/core/styles';
import {DirectionsCar} from '@material-ui/icons';

const VehicleReferencesPage = ({classes, vehicleReferences, theme}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setDats] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [shouldReload, setShouldReload] = useState({typenscheinnummer: false, stammnummer: false});

  const [filters, setFilters] = useState({});
  const [tab, setTab] = useState(0);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setDats([]);
    setOpenDialog(false);
  };

  const handleAdd = () => {
    setDats((prevState) => [
      ...prevState,
      {
        type: vehicleReferences.type,
        k_type: parseInt(vehicleReferences.k_type),
        number: vehicleReferences.number,
        vehicle_type: vehicleReferences.vehicle_type,
      },
    ]);
  };

  const handleRemove = (index) => {
    setDats((prevState) => [...prevState.filter((r, i) => i !== index)]);
  };

  const handleBulkSubmit = async () => {
    setSubmitting(true);

    const reload = {typenscheinnummer: false, stammnummer: false};
    for (let i = 0; i < data.length; i++) {
      const record = data[i];
      if (record.type === 'typenscheinnummer') {
        const {request} = await postTypenscheinnummer({
          k_type: record.k_type,
          number: record.number,
          vehicle_type: record.vehicle_type,
        });
        if (
          request.status === 400 &&
          request.responseText?.includes('Attempt to insert a duplicate')
        ) {
          fireErrorToast(<Trans>Attempt to insert a duplicate - {record.number}</Trans>, {
            autoClose: false,
          });
        } else {
          reload.typenscheinnummer = true;
        }
      } else if (record.type === 'stammnummer') {
        const {request} = await postStammnummer({
          k_type: record.k_type,
          number: record.number,
          vehicle_type: record.vehicle_type,
        });

        if (
          request.status === 400 &&
          request.responseText?.includes('Attempt to insert a duplicate')
        ) {
          fireErrorToast(<Trans>Attempt to insert a duplicate - {record.number}</Trans>, {
            autoClose: false,
          });
        } else {
          reload.stammnummer = true;
        }
      }
    }

    setShouldReload(reload);
    setSubmitting(false);
    setDats([]);
    handleCloseDialog();
  };

  const submitFilters = (values) => {
    setFilters(values);
  };

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <GridContainer>
      {/* Filter */}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="blue" icon>
            <CardIcon color="blue">
              <b>
                <Trans>Filter</Trans>
              </b>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <FilterVRForm form={'vehicleReferencesFilters'} onSubmit={submitFilters} />
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="blue" icon>
            <CardIcon color="blue">
              <DirectionsCar />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Trans>Vehicle references</Trans>
            </h4>

            <VehicleReferencesTableToolbar onAdd={handleOpenDialog} />

            <div className={classes.tabBar}>
              <AppBar position="static" color="default">
                <CommonTabs value={tab} onChange={(e, newValue) => handleTabChange(newValue)}>
                  <Tab label={<Trans>Typenscheinnummer</Trans>} />
                  <Tab label={<Trans>Stammnummer</Trans>} />
                </CommonTabs>
              </AppBar>
            </div>
          </CardHeader>

          <CardBody>
            <Dialog
              maxWidth={'lg'}
              fullWidth={true}
              open={openDialog}
              handleClose={handleCloseDialog}
              title={<Trans>Add vehicle reference</Trans>}
              content={
                <div style={{position: 'relative'}}>
                  {submitting && (
                    <div className={classes.referenceLoading}>
                      <CircularProgress />
                    </div>
                  )}
                  <VehicleReferencesForm
                    form={'vehicleReferencesForm'}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    data={data}
                    formData={vehicleReferences}
                  />

                  <div className={classes.formActions}>
                    <Button
                      color={'primary'}
                      disabled={submitting || !data.length}
                      onClick={handleBulkSubmit}
                    >
                      <Trans>Save changes</Trans>
                    </Button>
                  </div>
                </div>
              }
            />

            <SwipeableView index={tab} onChangeIndex={handleTabChange}>
              <TabContainer dir={theme.direction}>
                <VRTypenscheinnummerTable
                  filters={filters}
                  shouldReload={shouldReload.typenscheinnummer}
                  setShouldReload={setShouldReload}
                />
              </TabContainer>

              <TabContainer dir={theme.direction}>
                <VRStamnummerTable
                  filters={filters}
                  shouldReload={shouldReload.stammnummer}
                  setShouldReload={setShouldReload}
                />
              </TabContainer>
            </SwipeableView>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postStammnummer,
      postTypenscheinnummer,
    },
    dispatch
  );
};

export default compose(
  withStyles(vehicleReferencesPageStyle, {withTheme: true}),
  connect((store) => {
    return {vehicleReferences: getFormValues('vehicleReferencesForm')(store)};
  }, mapDispatchToProps)
)(VehicleReferencesPage);

import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader, updateLocalLoader} from '_actions/global/loaderActions';
import {
  getCartLockInfo,
  getCarts,
  lockCarts,
  orderCarts,
  updateCart,
} from '_actions/projectSpecific/cartsActions';
import {getWaitingOrders, postWaitingOrders} from '_actions/projectSpecific/configActions';
import {loadTheme} from '_assets/projectSpecific/jss/views/cartsPageStyle';
import {ordersPageStyle} from '_assets/projectSpecific/jss/views/ordersPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import SendOrderDialog from '_components/projectSpecific/Dialogs/SendOrderDialog';
import DetailOrderDialog from '_components/projectSpecific/Forms/DetailOrderDialog';
import FilterOrderForm from '_components/projectSpecific/Forms/FilterOrderForm';
import {DATE_TIME_FORMAT} from '_config/configConstants';
import authorizedAbility from '_helpers/Authorization';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {
  actualUserSelector,
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/functions';
import {browserHistoryAccessor} from 'App/history';
import cx from 'classnames';
import {diff} from 'deep-object-diff';
import moment from 'moment';
import React, {Component} from 'react';
import Countdown from 'react-countdown-now';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Cancel, Lock, LockOpen, ShoppingCart, Visibility} from '@material-ui/icons';

const rows = [
  {
    id: 'action',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
  {
    id: 'id',
    label: <Trans>Basket entry No.</Trans>,
    sortable: true,
  },
  {
    id: 'owner__customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'owner__name_1',
    label: <Trans>Customer name</Trans>,
    sortable: true,
  },
  {
    id: 'owner__city',
    label: <Trans>Customer city</Trans>,
    sortable: true,
  },
  {
    id: 'created_at',
    label: <Trans>Date created</Trans>,
    sortable: true,
  },
  {
    id: 'ref_number',
    label: <Trans>Order ref.</Trans>,
    sortable: true,
  },
  {
    id: 'ordered',
    label: <Trans>Ordered in Rhiag</Trans>,
    sortable: true,
  },
];

class OrdersPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      order: 'asc',
      selected: [],
      page: 0,
      orderBy: this.props.cartListFilter.orderBy,
      from: this.props.cartListFilter.from,
      to: this.props.cartListFilter.to,
      customerNo: this.props.cartListFilter.customerNo,
      status: null,
      ordered: this.props.cartListFilter.ordered,
      openDetailCartDialog: false,
      openDialogSendOrder: false,
      detailId: null,
      customerId: null,
      currentCart: null,
      anchorEl: null,
      openLock: false,
      openUnlock: false,
    };
    if (authorizedAbility.cannot('read', 'Order')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  fetchCarts = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getCarts(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.customerNo,
        this.state.from,
        this.state.to,
        this.state.status,
        this.state.ordered
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  filterCarts = (values) => {
    this.setState(
      {
        customerNo: values.customerNo,
        from: values.from,
        to: values.to,
        ordered: values.ordered,
      },
      () => this.fetchCarts()
    );
  };

  handleResetCartsFiltering = () => {
    this.setState(
      {
        customerNo: null,
        from: null,
        to: null,
        ordered: false,
      },
      () => this.fetchCarts()
    );
  };

  handleCloseDetailOrderDialogWithUnlock = () => {
    this.props.updateLocalLoader({localLoading: true});
    return this.props
      .lockCarts([this.state.detailId], false)
      .then(() => {
        this.setState(
          {
            openDetailCartDialog: false,
            detailId: null,
            customerId: null,
            currentCart: null,
          },
          () => this.fetchCarts()
        );
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket is unlocked.</Trans>);
      })
      .catch((error) => {
        this.setState({
          openDetailCartDialog: false,
          detailId: null,
          customerId: null,
          currentCart: null,
        });
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleOpenDetailCartDialog = (id, customerId, cart) => {
    this.setState({
      openDetailCartDialog: true,
      detailId: id,
      customerId: customerId,
      currentCart: cart,
    });
  };

  handleCloseDetailOrderDialog = () => {
    this.setState(
      {
        openDetailCartDialog: false,
        detailId: null,
        customerId: null,
        currentCart: null,
      },
      () => this.fetchCarts()
    );
  };

  handleOpenSendOrderDialog = () => {
    this.setState({
      openDialogSendOrder: true,
    });
  };

  handleCloseSendOrderDialog = () => {
    this.setState({
      openDialogSendOrder: false,
    });
  };

  sendOrderToRhiag = (id, values) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .updateCart(id, diff(this.state.currentCart, this.props.detailCartFormData))
      .then(() => {
        this.props
          .orderCarts([id], values)
          .then((response) => {
            this.setState({
              openDialogSendOrder: false,
              currentCart: response.data.objects ? response.data.objects[0] : null,
            });
            this.toastId = fireSuccessToast(<Trans>Successfully ordered.</Trans>);
          })
          .catch((error) => {
            this.setState({
              openDialogSendOrder: false,
              currentCart: null,
            });
            this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
          });

        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket was successfully saved.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleUnlockOpen = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const {currentTarget} = event;
    this.setState(
      {
        anchorEl: currentTarget,
        openUnlock: true,
        detailId: id,
      },
      () => this.fetchLockInfo(id)
    );
  };

  handleUnlockClose = () => {
    this.setState({
      openUnlock: false,
      detailId: null,
    });
  };

  fetchLockInfo = (id) => {
    this.props.getCartLockInfo(id);
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchCarts()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchCarts());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchCarts());
    });
  };

  handleSendAllOrders = () => {
    this.props.updateLoader({globalLoading: true});
    this.props
      .postWaitingOrders({cart_ids: []})
      .then((res) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>All orders sent to Rhiag</Trans>);
        this.fetchCarts();
      })
      .catch((err) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(err.errors._error, {autoClose: false});
      });
  };

  componentDidMount = () => {
    this.fetchCarts();
    this.props.getWaitingOrders();
  };

  render() {
    const {classes, localLoader, cartListFilter} = this.props;
    const {order, page, orderBy} = this.state;

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card chart>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterOrderForm
                form="filterOrderForm"
                initialValues={cartListFilter}
                onSubmit={this.filterCarts}
                handleReset={this.handleResetCartsFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <ShoppingCart />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Orders</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              {this.state.openDetailCartDialog && (
                <DetailOrderDialog
                  handleClose={this.handleCloseDetailOrderDialog}
                  handleCloseWithUnlock={this.handleCloseDetailOrderDialogWithUnlock}
                  fullScreen
                  open={this.state.openDetailCartDialog}
                  id={this.state.detailId}
                  customerId={this.state.customerId}
                  handleSendOrderCartDialogOpen={this.handleOpenSendOrderDialog}
                  currentUserId={this.props.currentUser.id}
                  cartDetail={this.state.currentCart}
                  handleCloseDetailOrderDialog={this.handleCloseDetailOrderDialog}
                />
              )}

              {this.state.openDialogSendOrder && (
                <SendOrderDialog
                  handleClose={this.handleCloseSendOrderDialog}
                  open={this.state.openDialogSendOrder}
                  onSubmit={(values) => {
                    return this.sendOrderToRhiag(this.state.detailId, values);
                  }}
                />
              )}
              {/* hidden for now */}
              {/*<Can I="update" a="Order">*/}
              {/*  <div className={classes.flexEnd}>*/}
              {/*    <Tooltip title={<Trans>Send all orders to Rhiag</Trans>}>*/}
              {/*      <Button*/}
              {/*        ref="export"*/}
              {/*        onClick={this.handleSendAllOrders}*/}
              {/*        disabled={*/}
              {/*          !waitingOrders.can_be_sent_to_oracle || waitingOrders.carts_to_be_sent === 0*/}
              {/*        }*/}
              {/*        className={cx(*/}
              {/*          waitingOrders.can_be_sent_to_oracle*/}
              {/*            ? classes.allowSendAllOrdersColor*/}
              {/*            : classes.notAllowSendAllOrdersColor*/}
              {/*        )}*/}
              {/*      >*/}
              {/*        {waitingOrders.can_be_sent_to_oracle ? (*/}
              {/*          <Trans>Send all to Rhiag</Trans>*/}
              {/*        ) : (*/}
              {/*          <Trans>Order mode is set to OFFLINE</Trans>*/}
              {/*        )}*/}
              {/*      </Button>*/}
              {/*    </Tooltip>*/}
              {/*  </div>*/}
              {/*</Can>*/}

              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.carts.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.carts.map((cart) => {
                      const {id, is_locked, owner, created_at, ref_number, ordered, seen} = cart;
                      const lockInfo = this.props.lockInfo;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={id}
                          className={cx({
                            [classes.unseenRow]: !seen,
                          })}
                        >
                          <TableCell className={classes.cell}>
                            <div className={classes.actions}>
                              {/* TODO add read permission */}
                              <BaseActionsButton
                                color="info"
                                onClick={() =>
                                  this.handleOpenDetailCartDialog(id, owner.customer.id, cart)
                                }
                              >
                                <Tooltip title={<Trans>Open detail of order.</Trans>}>
                                  <Visibility />
                                </Tooltip>
                              </BaseActionsButton>
                              {localLoader.localLoading && this.state.detailId === id ? (
                                <MuiThemeProvider theme={loadTheme}>
                                  <CircularProgress key={id} />
                                </MuiThemeProvider>
                              ) : (
                                <>
                                  {is_locked === false ? (
                                    <div>
                                      <BaseActionsButton color="success" disabled>
                                        <LockOpen />
                                      </BaseActionsButton>
                                    </div>
                                  ) : (
                                    <>
                                      <BaseActionsButton
                                        color="danger"
                                        aria-owns={
                                          this.state.openUnlock ? 'unlockDetail' : undefined
                                        }
                                        aria-label="Detail of locked cart"
                                        aria-haspopup="true"
                                        onClick={(event) => this.handleUnlockOpen(event, id)}
                                      >
                                        <Tooltip title={<Trans>Unlock this order</Trans>}>
                                          <Lock />
                                        </Tooltip>
                                      </BaseActionsButton>
                                      <Popper
                                        id={id}
                                        open={this.state.openUnlock && this.state.detailId === id}
                                        anchorEl={this.anchorEl}
                                        transition
                                        disablePortal
                                        placement="bottom-end"
                                        className={cx({
                                          [classes.popperResponsive]: true,
                                        })}
                                      >
                                        {({TransitionProps}) => (
                                          <Grow
                                            {...TransitionProps}
                                            id="unlockDetail"
                                            style={{transformOrigin: '0 0 0'}}
                                          >
                                            <Paper className={classes.dropDown}>
                                              <div className={classes.headerDropDown}>
                                                <h5>
                                                  <Trans>Lock info</Trans>
                                                </h5>
                                                <IconButton onClick={this.handleUnlockClose}>
                                                  <Cancel />
                                                </IconButton>
                                              </div>
                                              <Lock />
                                              <div>
                                                <strong>
                                                  <Trans>Locked by </Trans>:{' '}
                                                </strong>
                                                {lockInfo.operator_username}
                                              </div>
                                              <div>
                                                <strong>
                                                  <Trans>Last modified</Trans>:{' '}
                                                </strong>
                                                {moment(lockInfo.updated_at)
                                                  .format(DATE_TIME_FORMAT)
                                                  .replace(/ /g, '\u00a0')}
                                              </div>
                                              <div>
                                                <strong>
                                                  <Trans>Basket will be unlocked in</Trans>:
                                                </strong>
                                                {lockInfo.unlocked_at ? (
                                                  <div className={classes.countdownTimer}>
                                                    <Countdown date={lockInfo.unlocked_at} />
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                              {lockInfo.operator_id ===
                                              this.props.currentUser.id ? (
                                                <Can I="lock" a="Carts">
                                                  <div className={classes.buttonPosition}>
                                                    <Button
                                                      color="blue"
                                                      onClick={this.handleUnlockCart}
                                                    >
                                                      <Trans>Unlock</Trans>
                                                    </Button>
                                                  </div>
                                                </Can>
                                              ) : (
                                                ''
                                              )}
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{id}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.customer_no : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.name : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.city : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {moment(created_at).format(DATE_TIME_FORMAT).replace(/ /g, '\u00a0')}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{ref_number}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {ordered === false ? (
                              <div className={classes.dangerTextColor}>
                                <Trans>No</Trans>
                              </div>
                            ) : (
                              <div className={classes.successTextColor}>
                                <Trans>Yes</Trans>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateLocalLoader,
      getCarts,
      updateCart,
      orderCarts,
      getWaitingOrders,
      postWaitingOrders,
      updateGlobalSettings,
      lockCarts,
      getCartLockInfo,
    },
    dispatch
  );
};

export default compose(
  withStyles(ordersPageStyle),
  connect((store) => {
    return {
      cartListFilter: store.AppFilterStates.cartListFilter,
      localLoader: store.LocalLoader,
      globalSettings: store.GlobalSettings,
      waitingOrders: store.Config.waitingOrders,
      carts: store.Carts.data,
      total: store.Carts.total,
      lockInfo: store.LockInfo.data,
      currentUser: actualUserSelector(store.Auth),
    };
  }, mapDispatchToProps)
)(OrdersPage);

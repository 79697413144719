import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader, updateLocalLoader} from '_actions/global/loaderActions';
import {
  deliverCarts,
  exportCarts,
  getCartLockInfo,
  getCarts,
  getFilteredCartsExport,
  lockCarts,
  orderCarts,
  updateCart,
} from '_actions/projectSpecific/cartsActions';
import {
  cartsPageStyle,
  loadTheme,
  tableCheckboxTheme,
} from '_assets/projectSpecific/jss/views/cartsPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTableHead from '_components/global/Tables/CustomTableHead';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import DetailCartDialog from '_components/projectSpecific/Dialogs/DetailCartDialog';
import SendOrderDialog from '_components/projectSpecific/Dialogs/SendOrderDialog';
import FilterCartForm from '_components/projectSpecific/Forms/FilterCartForm';
import CartsTableToolbar2 from '_components/projectSpecific/Toolbars/CartsTableToolbar2';
import {DATE_TIME_FORMAT} from '_config/configConstants';
import authorizedAbility from '_helpers/Authorization';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {
  actualUserSelector,
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
  selectConcat,
} from '_helpers/functions';
import {browserHistoryAccessor} from 'App/history';
import cx from 'classnames';
import {diff} from 'deep-object-diff';
import download from 'downloadjs';
import moment from 'moment';
import React, {Component} from 'react';
import Countdown from 'react-countdown-now';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector, getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Cancel, Lock, LockOpen, ShoppingCart, Visibility} from '@material-ui/icons';

const rows = [
  {
    id: 'action',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
  {
    id: 'id',
    label: <Trans>Basket entry No.</Trans>,
    sortable: true,
  },
  {
    id: 'owner__customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'owner__name_1',
    label: <Trans>Customer name</Trans>,
    sortable: true,
  },
  {
    id: 'owner__city',
    label: <Trans>Customer city</Trans>,
    sortable: true,
  },
  {
    id: 'created_at',
    label: <Trans>Date created</Trans>,
    sortable: true,
  },
  {
    id: 'status',
    label: <Trans>Status</Trans>,
    sortable: true,
  },
  {
    id: 'note',
    label: <Trans>Note</Trans>,
    sortable: true,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getCarts,
      updateCart,
      lockCarts,
      updateLocalLoader,
      updateGlobalSettings,
      getCartLockInfo,
      exportCarts,
      deliverCarts,
      orderCarts,
      getFilteredCartsExport,
    },
    dispatch
  );
};

class CartsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      order: 'asc',
      selected: [],
      page: 0,
      orderBy: this.props.CartListFilters.orderBy,
      from: this.props.CartListFilters.from,
      to: this.props.CartListFilters.to,
      customerNo: this.props.CartListFilters.customerNo,
      status: this.props.CartListFilters.status,

      openDetailCartDialog: false,
      detailId: null,
      anchorEl: null,
      openLock: false,
      openUnlock: false,
      openExport: false,
      anchorExportEl: null,
      exportedFormat: '',
      openDialogSendOrder: false,
      customerId: null,
      currentCart: null,
    };
    if (authorizedAbility.cannot('read', 'Carts')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  fetchCarts = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getCarts(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.customerNo,
        this.state.from,
        this.state.to,
        this.state.status
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  componentDidMount = () => {
    this.fetchCarts();
  };

  fetchLockInfo = (id) => {
    this.props.getCartLockInfo(id);
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchCarts()
    );
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState({selected: this.props.carts.map((cart) => cart.id)});
      return;
    }
    this.setState({selected: []});
  };

  handleClick = (event, id) => {
    this.setState({selected: selectConcat(id, this.state.selected)});
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchCarts());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchCarts());
    });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  filterCarts = (values) => {
    this.setState(
      {
        customerNo: values.customerNo,
        from: values.from,
        to: values.to,
        status: values.status,
      },
      () => this.fetchCarts()
    );
  };

  handleResetCartsFiltering = () => {
    this.setState(
      {
        customerNo: null,
        from: null,
        to: null,
        status: 'new,partly_done',
      },
      () => this.fetchCarts()
    );
  };

  handleOpenDetailCartDialog = (id, customerId, cart) => {
    this.setState({
      openDetailCartDialog: true,
      detailId: id,
      customerId: customerId,
      currentCart: cart,
    });
  };

  handleCloseDetailCartDialogWithUnlock = () => {
    this.props.updateLocalLoader({localLoading: true});
    return this.props
      .lockCarts([this.state.detailId], false)
      .then(() => {
        this.setState(
          {
            openDetailCartDialog: false,
            detailId: null,
            customerId: null,
            currentCart: null,
          },
          () => this.fetchCarts()
        );
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket is unlocked.</Trans>);
      })
      .catch((error) => {
        this.setState({
          openDetailCartDialog: false,
          detailId: null,
          customerId: null,
          currentCart: null,
        });
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleCloseDetailCartDialog = () => {
    this.setState(
      {
        openDetailCartDialog: false,
        detailId: null,
        customerId: null,
        currentCart: null,
      },
      () => this.fetchCarts()
    );
  };

  handleUnlockCart = () => {
    this.props.updateLocalLoader({localLoading: true});
    return this.props
      .lockCarts([this.state.detailId], false)
      .then(() => {
        this.setState(
          {
            openUnlock: false,
            detailId: null,
          },
          () => this.fetchCarts()
        );
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket is unlocked.</Trans>);
      })
      .catch((error) => {
        this.setState({
          openUnlock: false,
          detailId: null,
        });
        this.props.updateLocalLoader({localLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleUnlockOpen = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const {currentTarget} = event;
    this.setState(
      {
        anchorEl: currentTarget,
        openUnlock: true,
        detailId: id,
      },
      () => this.fetchLockInfo(id)
    );
  };

  handleUnlockClose = () => {
    this.setState({
      openUnlock: false,
      detailId: null,
    });
  };

  saveBasket = (id, values) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .updateCart(id, values)
      .then(() => {
        this.toastId = fireSuccessToast(<Trans>Basket was successfully saved.</Trans>);
        this.props
          .lockCarts([id], false)
          .then(() => {
            this.toastId = fireSuccessToast(<Trans>Basket is unlocked.</Trans>);
            this.setState(
              {
                openDetailCartDialog: false,
              },
              () => this.fetchCarts()
            );
            this.props.updateLoader({globalLoading: false});
          })
          .catch((error) => {
            this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
          });
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleExportCarts = (ids) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .exportCarts(ids, this.props.format)
      .then((response) => {
        download(response.data, response.headers['x-filename'], this.props.format);
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Successfully downloaded.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleDeliverLogic = (id, sendEmail = false) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .updateCart(id, diff(this.state.currentCart, this.props.detailCartFormData))
      .then(() => {
        this.props
          .deliverCarts([id], sendEmail)
          .then(() => {
            this.props
              .exportCarts([id], this.props.format)
              .then((response) => {
                download(response.data, response.headers['x-filename'], this.props.format);
                this.toastId = fireSuccessToast(<Trans>Successfully downloaded.</Trans>);
              })
              .catch((error) => {
                this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
              });

            this.setState(
              {
                openDetailCartDialog: false,
              },
              () => this.fetchCarts()
            );
            if (sendEmail === true) {
              this.toastId = fireSuccessToast(
                <Trans>Successfully delivered and email sent.</Trans>
              );
            } else {
              this.toastId = fireSuccessToast(<Trans>Successfully delivered.</Trans>);
            }
          })
          .catch((error) => {
            this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
          });
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket was successfully saved.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleOpenSendOrderDialog = () => {
    this.setState({
      openDialogSendOrder: true,
    });
  };

  handleCloseSendOrderDialog = () => {
    this.setState({
      openDialogSendOrder: false,
    });
  };

  sendOrderToRhiag = (id, values) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .updateCart(id, diff(this.state.currentCart, this.props.detailCartFormData))
      .then(() => {
        this.props
          .orderCarts([id], values)
          .then((response) => {
            this.setState({
              openDialogSendOrder: false,
              currentCart: response.data.objects ? response.data.objects[0] : null,
            });
            this.toastId = fireSuccessToast(<Trans>Successfully ordered.</Trans>);
          })
          .catch((error) => {
            this.setState({
              openDialogSendOrder: false,
              currentCart: null,
            });
            this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
          });

        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Basket was successfully saved.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  render() {
    const {classes, localLoader} = this.props;
    const {order, selected, page, orderBy} = this.state;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card chart>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterCartForm
                form="filterCartForm"
                initialValues={this.props.CartListFilters}
                onSubmit={this.filterCarts}
                handleReset={this.handleResetCartsFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <ShoppingCart />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Shopping carts</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              {this.state.openDetailCartDialog && (
                <DetailCartDialog
                  handleClose={this.handleCloseDetailCartDialog}
                  handleCloseWithUnlock={this.handleCloseDetailCartDialogWithUnlock}
                  fullScreen
                  open={this.state.openDetailCartDialog}
                  id={this.state.detailId}
                  customerId={this.state.customerId}
                  onSubmit={(values) => {
                    return this.saveBasket(this.state.detailId, values);
                  }}
                  handleDeliverCart={() => this.handleDeliverLogic(this.state.detailId)}
                  handleDeliverCartEmail={() => this.handleDeliverLogic(this.state.detailId, true)}
                  handleSendOrderCartDialogOpen={this.handleOpenSendOrderDialog}
                  currentUserId={this.props.currentUser.id}
                  cartDetail={this.state.currentCart}
                />
              )}
              {this.state.openDialogSendOrder && (
                <SendOrderDialog
                  handleClose={this.handleCloseSendOrderDialog}
                  open={this.state.openDialogSendOrder}
                  onSubmit={(values) => {
                    return this.sendOrderToRhiag(this.state.detailId, values);
                  }}
                />
              )}
              <CartsTableToolbar2
                numSelected={selected.length}
                handleExport={() => this.handleExportCarts(selected)}
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <CustomTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.carts.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.carts.map((cart) => {
                      const {id, is_locked, owner, created_at, status, note, seen} = cart;
                      const isSelected = this.isSelected(id);
                      const lockInfo = this.props.lockInfo;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={id}
                          selected={isSelected}
                          className={cx({
                            [classes.unseenRow]: !seen,
                          })}
                        >
                          <TableCell padding="checkbox" classes={{root: classes.cell}}>
                            <MuiThemeProvider theme={tableCheckboxTheme}>
                              <Checkbox
                                color="primary"
                                checked={isSelected}
                                onClick={(event) => this.handleClick(event, id)}
                              />
                            </MuiThemeProvider>
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            <div className={classes.actions}>
                              <Can I="read" a="CartItems">
                                <BaseActionsButton
                                  color="info"
                                  onClick={() =>
                                    this.handleOpenDetailCartDialog(id, owner.customer.id, cart)
                                  }
                                >
                                  <Tooltip title={<Trans>Open detail of basket.</Trans>}>
                                    <Visibility />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              {localLoader.localLoading && this.state.detailId === id ? (
                                <MuiThemeProvider theme={loadTheme}>
                                  <CircularProgress key={id} />
                                </MuiThemeProvider>
                              ) : (
                                <>
                                  {is_locked === false ? (
                                    <div>
                                      <BaseActionsButton color="success" disabled>
                                        <LockOpen />
                                      </BaseActionsButton>
                                    </div>
                                  ) : (
                                    <>
                                      <BaseActionsButton
                                        color="danger"
                                        aria-owns={
                                          this.state.openUnlock ? 'unlockDetail' : undefined
                                        }
                                        aria-label="Detail of locked cart"
                                        aria-haspopup="true"
                                        onClick={(event) => this.handleUnlockOpen(event, id)}
                                      >
                                        <Tooltip title={<Trans>Unlock this basket</Trans>}>
                                          <Lock />
                                        </Tooltip>
                                      </BaseActionsButton>
                                      <Popper
                                        id={id}
                                        open={this.state.openUnlock && this.state.detailId === id}
                                        anchorEl={this.anchorEl}
                                        transition
                                        disablePortal
                                        placement="bottom-end"
                                        className={cx({
                                          [classes.popperResponsive]: true,
                                        })}
                                      >
                                        {({TransitionProps}) => (
                                          <Grow
                                            {...TransitionProps}
                                            id="unlockDetail"
                                            style={{transformOrigin: '0 0 0'}}
                                          >
                                            <Paper className={classes.dropDown}>
                                              <div className={classes.headerDropDown}>
                                                <h5>
                                                  <Trans>Lock info</Trans>
                                                </h5>
                                                <IconButton onClick={this.handleUnlockClose}>
                                                  <Cancel />
                                                </IconButton>
                                              </div>
                                              <Lock />
                                              <div>
                                                <strong>
                                                  <Trans>Locked by </Trans>:{' '}
                                                </strong>
                                                {lockInfo.operator_username}
                                              </div>
                                              <div>
                                                <strong>
                                                  <Trans>Last modified</Trans>:{' '}
                                                </strong>
                                                {moment(lockInfo.updated_at)
                                                  .format(DATE_TIME_FORMAT)
                                                  .replace(/ /g, '\u00a0')}
                                              </div>
                                              <div>
                                                <strong>
                                                  <Trans>Basket will be unlocked in</Trans>:
                                                </strong>
                                                {lockInfo.unlocked_at ? (
                                                  <div className={classes.countdownTimer}>
                                                    <Countdown date={lockInfo.unlocked_at} />
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                              {lockInfo.operator_id ===
                                              this.props.currentUser.id ? (
                                                <Can I="lock" a="Carts">
                                                  <div className={classes.buttonPosition}>
                                                    <Button
                                                      color="blue"
                                                      onClick={this.handleUnlockCart}
                                                    >
                                                      <Trans>Unlock</Trans>
                                                    </Button>
                                                  </div>
                                                </Can>
                                              ) : (
                                                ''
                                              )}
                                            </Paper>
                                          </Grow>
                                        )}
                                      </Popper>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{id}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.customer_no : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.name : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {owner && owner.customer ? owner.customer.city : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {moment(created_at).format(DATE_TIME_FORMAT).replace(/ /g, '\u00a0')}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{status}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{note}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('exportForm');
const selector2 = formValueSelector('filterCartForm');

export default compose(
  withStyles(cartsPageStyle),
  connect((store) => {
    return {
      CartListFilters: store.AppFilterStates.cartListFilter,
      carts: store.Carts.data,
      total: store.Carts.total,
      localLoader: store.LocalLoader,
      globalSettings: store.GlobalSettings,
      lockInfo: store.LockInfo.data,
      format: selector(store, 'export'),
      filterCustomerNumber: selector2(store, 'customerNo'),
      filterDateFrom: selector2(store, 'from'),
      filterDateTo: selector2(store, 'to'),
      filterStatus: selector2(store, 'status'),
      detailCartFormData: getFormValues('detailCartForm')(store),
      currentUser: actualUserSelector(store.Auth),
    };
  }, mapDispatchToProps)
)(CartsPage);

import customersDeliveryOptionsTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/customersDeliveryOptionsTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import CustomersDeliveryOptionsForm from '_components/projectSpecific/Forms/CustomersDeliveryOptionsForm';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {fireWarningToast} from '_helpers/functions';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import compose from 'recompose/compose';
import {Trans} from '@lingui/macro';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {AddCircleOutline, Delete} from '@material-ui/icons';

class CustomersDeliveryOptionsTableToolbar extends Component {
  toastId = null;

  warningSelect = () => {
    this.toastId = fireWarningToast(<Trans>Select at least one customer!</Trans>, {
      autoClose: false,
    });
  };

  render() {
    const {
      numSelected,
      classes,
      handleAddDeliveryTerms,
      handleDeleteDeliveryTerms,
      // handleExport,
      deliveryOptions,
      initDTValues,
    } = this.props;

    const Actions = (
      <>
        <div className={cx(classes.flexEnd, classes.hiddenSmall)}>
          <CustomersDeliveryOptionsForm
            form="customersDeliveryTermsForm"
            options={deliveryOptions}
            initialValues={initDTValues}
          />
          <div className={classes.buttons}>
            <Can I="update" a="DeliveryOptionMembership">
              <Tooltip title={<Trans>Add chosen delivery term to selected customers</Trans>}>
                <Button onClick={numSelected < 1 ? this.warningSelect : handleAddDeliveryTerms}>
                  <Trans>Add</Trans>
                </Button>
              </Tooltip>
            </Can>
            <Can I="delete" a="DeliveryOptionMembership">
              <Tooltip title={<Trans>Delete chosen delivery term from selected customers</Trans>}>
                <Button onClick={numSelected < 1 ? this.warningSelect : handleDeleteDeliveryTerms}>
                  <Trans>Delete</Trans>
                </Button>
              </Tooltip>
            </Can>
            {/*<Tooltip title={(<Trans>Download selected customers with delivery terms</Trans>)}>*/}
            {/*<Button*/}
            {/*ref="export"*/}
            {/*onClick={numSelected < 1 ? this.warningSelect : handleExport}*/}
            {/*>*/}
            {/*<Trans>Download selected</Trans>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
          </div>
        </div>
        <div className={cx(classes.hiddenBig)}>
          <CustomersDeliveryOptionsForm
            form="customersDeliveryTermsForm"
            options={deliveryOptions}
            initialValues={initDTValues}
          />
          <div className={classes.buttons}>
            <Can I="update" a="DeliveryOptionMembership">
              <Tooltip title={<Trans>Add chosen delivery term to selected customers</Trans>}>
                <Button onClick={numSelected < 1 ? this.warningSelect : handleAddDeliveryTerms}>
                  <AddCircleOutline />
                </Button>
              </Tooltip>
            </Can>
            <Can I="delete" a="DeliveryOptionMembership">
              <Tooltip title={<Trans>Delete chosen delivery term from selected customers</Trans>}>
                <Button onClick={numSelected < 1 ? this.warningSelect : handleDeleteDeliveryTerms}>
                  <Delete />
                </Button>
              </Tooltip>
            </Can>
            {/*<Tooltip title={(<Trans>Download selected customers with delivery terms</Trans>)}>*/}
            {/*<Button*/}
            {/*ref="export"*/}
            {/*onClick={numSelected < 1 ? this.warningSelect : handleExport}*/}
            {/*>*/}
            {/*<CloudDownload/>*/}
            {/*</Button>*/}
            {/*</Tooltip>*/}
          </div>
        </div>
      </>
    );

    return (
      <div>
        {/*view for Mobile*/}
        <Hidden mdUp implementation="css">
          <Toolbar
            className={cx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <div className={classes.title}>
              {numSelected > 0 ? (
                <>
                  <div>{Actions}</div>
                  <Typography color="inherit" variant="subtitle1">
                    <Trans>{numSelected} selected</Trans>
                  </Typography>
                </>
              ) : (
                <div className={cx(classes.actions)}>{Actions}</div>
              )}
            </div>
          </Toolbar>
        </Hidden>

        {/*view for Desktop*/}
        <Hidden mdDown implementation="css">
          <Toolbar
            className={cx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <div className={classes.title}>
              {numSelected > 0 && (
                <>
                  <Typography color="inherit" variant="subtitle1">
                    <Trans>{numSelected} selected</Trans>
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>{Actions}</div>
          </Toolbar>
        </Hidden>
      </div>
    );
  }
}

CustomersDeliveryOptionsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(customersDeliveryOptionsTableToolbarStyle))(
  CustomersDeliveryOptionsTableToolbar
);

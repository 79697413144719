import {blueButtonShadow, grayButton} from '_assets/global/jss/styleHelpers';

export const modulesStyle = (theme) => ({
  formActions: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  warningTitle: {
    fontSize: '18px',
    marginBottom: '20px',
  },
  button: grayButton,
  blue: blueButtonShadow,
});

import {getNotifications} from '_actions/global/notificationsActions';
import adminStyle from '_assets/global/jss/layouts/adminStyle';
import Footer from '_components/global/Footer/Footer';
import AdminNavbar from '_components/global/Navbars/AdminNavbar';
import Sidebar from '_components/global/Sidebar/Sidebar';
import withAdvancedRouter from '_components/global/hoc/withAdvancedRouter';
import {DESKTOP_BREAKPOINT, DEV_REFRESH_NOTIFICATIONS, ENVIRONMENT} from '_config/configConstants';
import useInterval from '_helpers/useIntervalHook';
import cx from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';

let ps;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotifications,
    },
    dispatch
  );
};

const AdminLayout = ({
  classes,
  getRoutesForLayoutSwitch,
  getRoutesForLayoutSidebar,
  getActiveRouteName,
  loader,
  localLoader,
  getNotifications,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [color] = useState('blue');
  const [bgColor] = useState('black');
  let mainPanelRef;

  const tick = useCallback(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    tick();
  }, [tick]);

  const shouldNotificationsRefresh =
    ENVIRONMENT === 'production' ||
    (ENVIRONMENT === 'development' && DEV_REFRESH_NOTIFICATIONS === true);
  useInterval(
    () => {
      tick();
    },
    shouldNotificationsRefresh ? 10000 : null
  );

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= DESKTOP_BREAKPOINT) {
        setMobileOpen(false);
      }
    }

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanelRef, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', handleResize);

    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        ps = null;
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [mainPanelRef]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const mainPanel =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    });

  const getLineProgressValue = !loader.globalLoading && !localLoader.localLoading;
  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.indeterminate, classes.positionLoader)}>
        <LinearProgress
          className={cx(classes.positionLoader, {
            [classes.hide]: getLineProgressValue,
          })}
          classes={{indeterminate: classes.indeterminate}}
        />
      </div>

      <Sidebar
        routes={getRoutesForLayoutSidebar('admin')}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        sidebarMinimize={sidebarMinimize}
      />
      <div className={mainPanel} ref={(node) => (mainPanelRef = node)} id="content-wrapper">
        <AdminNavbar
          // sidebarMinimize={sidebarMinimize}
          // miniActive={miniActive}
          brandText={getActiveRouteName()}
          handleDrawerToggle={handleDrawerToggle}
        />
        <div className={classes.content}>
          <div className={cx(classes.container, {[classes.hide]: loader.globalLoading})}>
            <Switch>{getRoutesForLayoutSwitch('admin')}</Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  getRoutesForLayoutSwitch: PropTypes.func.isRequired,
  getRoutesForLayoutSidebar: PropTypes.func.isRequired,
  getActiveRouteName: PropTypes.func.isRequired,
};

export default compose(
  withAdvancedRouter,
  withStyles(adminStyle),
  connect((store) => {
    return {
      loader: store.Loader,
      localLoader: store.LocalLoader,
    };
  }, mapDispatchToProps)
)(AdminLayout);

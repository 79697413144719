import {fireErrorToast} from '_helpers/functions';
import React from 'react';
import {Trans} from '@lingui/macro';

export function activationErrorHandle(error) {
  if (error.response) {
    const {status, data} = error.response;
    switch (status) {
      case 404:
      case 400:
        fireErrorToast(data.error_message);
        break;
      case 403:
        fireErrorToast(<Trans>Not allowed</Trans>);
        break;
      default:
        fireErrorToast(<Trans>Something went wrong</Trans>);
        break;
    }
  }
}

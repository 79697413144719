import {updateLoader} from '_actions/global/loaderActions';
import {getCart} from '_actions/projectSpecific/cartsActions';
import {getDeliveryOptions} from '_actions/projectSpecific/deliveryOptionsActions';
import {detailCartDialogStyle} from '_assets/projectSpecific/jss/components/Dialogs/detailCartDialogStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import DialogTitle from '_components/global/Dialogs/DialogTitle';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import DetailCartForm from '_components/projectSpecific/Forms/DetailCartForm';
import SetupDetailCartForm from '_components/projectSpecific/Forms/SetupDetailCartForm';
import {actualUserSelector, fireWarningToast} from '_helpers/functions';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {Visibility} from '@material-ui/icons';

const rows = [
  {
    id: 'product_no',
    label: <Trans>Product No.</Trans>,
    label2: <Trans>Description</Trans>,
    sortable: false,
  },
  {
    id: 'customer_delivery_date',
    label: <Trans>Customer delivery date</Trans>,
    label2: <Trans>Customer delivery option</Trans>,
    sortable: false,
  },
  {
    id: 'homologation',
    label: <Trans>Homologation</Trans>,
    label2: <Trans>Vehicle</Trans>,
    sortable: false,
  },
  {
    id: 'discount',
    label: <Trans>Discount in %</Trans>,
    label2: <Trans>Limited discount code</Trans>,
    sortable: false,
  },
  {
    id: 'retail_price',
    label: <Trans>Retail Price (No VAT)</Trans>,
    label2: <Trans>Customer price (No VAT)</Trans>,
    sortable: false,
  },
  {
    id: 'availability',
    label: <Trans>Availability</Trans>,
    label2: <Trans>Reseller price</Trans>,
    sortable: false,
  },
  {
    id: 'delivered',
    label: <Trans>Delivered</Trans>,
    label2: <Trans>Ordered</Trans>,
    sortable: false,
  },
  {
    id: 'deliver_qty',
    label: <Trans>Deliver qty</Trans>,
    label2: <Trans>Order qty RHIAG</Trans>,
    sortable: false,
  },
  {
    id: 'reference',
    label: <Trans>Reference</Trans>,
    label2: <Trans>Status</Trans>,
    sortable: false,
  },
];

const rows2 = [
  {
    id: 'delivery_address',
    label: <Trans>Delivery address</Trans>,
    sortable: false,
  },
  {
    id: 'setupAllLines',
    label: <Trans>Setup all items</Trans>,
    sortable: false,
  },
  {
    id: 'setDeliveryAddressOrders',
    label: <Trans>Set delivery address - orders</Trans>,
    sortable: false,
  },
  {
    id: 'totalCustomerPrice',
    label: <Trans>Total customer price</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getCart,
      getDeliveryOptions,
    },
    dispatch
  );
};

class DetailCartDialog extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    loading: true,
    loadingDeliveryOptions: true,
    openDialogSendOrder: false,
  };

  componentDidMount = () => {
    this.fetchCart();
    this.fetchDeliveryOptions();
  };

  toastId = null;

  fetchCart = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props.getCart(this.props.id).then(() => {
      this.setState({
        loading: false,
      });
      this.props.updateLoader({globalLoading: false});
    });
  };

  fetchDeliveryOptions = () => {
    return this.props.getDeliveryOptions().then(() => {
      this.setState({
        loadingDeliveryOptions: false,
      });
    });
  };

  getInitValues = (items) => {
    const initValues = {items: []};

    items &&
      items.forEach((item, index) => {
        initValues.items[index] = {};
        initValues.items[index].pk = item.id;
        initValues.items[index].delivery_date = item.delivery_date;
        if (item.delivery_option_id) {
          initValues.items[index].delivery_option_id = item.delivery_option_id;
        }
        initValues.items[index].deliver_qty = item.deliver_qty;
        initValues.items[index].order_qty = item.order_qty;
        initValues.items[index].status = item.status;
        if (this.props.deliveryDatesAll) {
          initValues.items[index].delivery_date = this.props.deliveryDatesAll;
        }
        if (this.props.deliveryOptionsAll) {
          initValues.items[index].delivery_option_id = this.props.deliveryOptionsAll;
        }
      });
    return initValues;
  };

  getOptions = (options) => {
    const listOptions = [];
    options.forEach((option) => {
      const o = {};
      o.value = option.id;
      o.label = option.name;
      listOptions.push(o);
    });
    return listOptions;
  };

  warningDeliver = () => {
    this.toastId = fireWarningToast(
      <Trans>At least one value of Deliver qty must be greater than 0.</Trans>
    );
  };

  warningOrder = () => {
    this.toastId = fireWarningToast(
      <Trans>At least one value of Order qty must be greater than 0.</Trans>
    );
  };

  render() {
    const {
      handleClose,
      handleCloseWithUnlock,
      open,
      classes,
      fullScreen,
      onSubmit,
      handleDeliverCart,
      handleDeliverCartEmail,
      handleSendOrderCartDialogOpen,
      currentUserId,
      cartDetail,
    } = this.props;
    const {id, total_customer_price, items, owner, shipment_address, operator_id} = this.props.cart;

    const cartItems = cartDetail && cartDetail.items ? cartDetail.items : items;
    const handleCloseFunction = currentUserId === operator_id ? handleCloseWithUnlock : handleClose;
    const actionsCondition = currentUserId === operator_id;

    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={handleCloseFunction}
        aria-labelledby="detailCart"
        open={open}
        classes={{paperFullScreen: classes.paperFullScreen}}
      >
        <DialogTitle id="detailCart" onClose={handleCloseFunction}>
          <Trans>Basket detail</Trans>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="blue" icon>
                  <CardIcon color="blue">
                    <Visibility />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {!this.state.loading && !this.state.loadingDeliveryOptions && (
                    <>
                      <SetupDetailCartForm
                        form="setupDetailCartForm"
                        rows2={rows2}
                        totalCustomerPrice={total_customer_price}
                        owner={owner}
                        shipmentAddress={shipment_address}
                        deliveryOptions={this.getOptions(this.props.deliveryTerms)}
                      />
                      <DetailCartForm
                        submitName={<Trans>Save</Trans>}
                        form="detailCartForm"
                        onSubmit={onSubmit}
                        initialValues={this.getInitValues(cartItems)}
                        rows1={rows}
                        key={id}
                        items={cartItems}
                        deliveryOptions={this.getOptions(this.props.deliveryTerms)}
                        handleDeliver={
                          (this.props.fromItems &&
                            this.props.fromItems?.filter((item) => item.deliver_qty > 0).length >
                              0) ||
                          cartItems?.filter((item) => item.deliver_qty > 0).length > 0
                            ? handleDeliverCart
                            : this.warningDeliver
                        }
                        handleDeliverEmail={
                          (this.props.fromItems &&
                            this.props.fromItems?.filter((item) => item.deliver_qty > 0).length >
                              0) ||
                          cartItems?.filter((item) => item.deliver_qty > 0).length > 0
                            ? handleDeliverCartEmail
                            : this.warningDeliver
                        }
                        handleSendOrderDialogOpen={
                          (this.props.fromItems &&
                            this.props.fromItems?.filter((item) => item.order_qty > 0).length >
                              0) ||
                          cartItems?.filter((item) => item.order_qty > 0).length > 0
                            ? handleSendOrderCartDialogOpen
                            : this.warningOrder
                        }
                        handleClose={handleCloseFunction}
                        condition={actionsCondition}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

DetailCartDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selector = formValueSelector('setupDetailCartForm');
const selector2 = formValueSelector('exportForm');
const selector3 = formValueSelector('detailCartForm');

export default compose(
  withMobileDialog(),
  withStyles(detailCartDialogStyle),
  connect((store) => {
    return {
      cart: store.Cart.data,
      deliveryDatesAll: selector(store, 'delivery_dates_all'),
      deliveryOptionsAll: selector(store, 'delivery_options_all'),
      useCocoAddress: selector(store, 'use_coco_shipment_address'),
      fromItems: selector3(store, 'items'),
      // orderQTY: selector3(store, "order_qty"),
      deliveryTerms: store.DeliveryOptions.data,
      format: selector2(store, 'export'),
      currentUser: actualUserSelector(store.Auth),
    };
  }, mapDispatchToProps)
)(DetailCartDialog);

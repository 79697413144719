import {
  dateTimePickerStyle,
  materialDateTimeDesktopTheme,
  materialDateTimeMobileTheme,
} from '_assets/global/jss/components/Pickers/dateTimePickerStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import cx from 'classnames';
import {DatePicker} from 'material-ui-pickers';
import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {MuiThemeProvider} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';

const CustomDatePicker = ({
  classes,
  dateValue,
  onDateChange,
  helpText,
  id,
  required,
  error,
  success,
  dateLabel,
  clearButton,
  formName,
  reduxName,
  dispatch,
  minDate,
}) => {
  let helpTextClasses = cx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
    [classes.helperTextMargin]: success && error,
  });

  return (
    <>
      <Hidden mdDown>
        <MuiThemeProvider theme={materialDateTimeDesktopTheme}>
          <FormControl>
            <div className={cx('picker', classes.dateTimePicker)}>
              <div className={classes.endIcon}>
                <DatePicker
                  keyboard
                  autoOk
                  clearable
                  format={'DD/MM/YYYY'}
                  required={required}
                  error={error}
                  label={dateLabel}
                  mask={(value) =>
                    // handle clearing outside if value can be changed outside the component
                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                  }
                  value={dateValue ? dateValue : null}
                  onChange={onDateChange}
                  disableOpenOnEnter
                  minDate={minDate}
                />
              </div>
              {clearButton === true && (
                <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
              )}
            </div>
            {helpText !== undefined ? (
              <FormHelperText id={id + '-text'} className={helpTextClasses}>
                {helpText}
              </FormHelperText>
            ) : null}
          </FormControl>
        </MuiThemeProvider>
      </Hidden>
      <Hidden lgUp>
        <MuiThemeProvider theme={materialDateTimeMobileTheme}>
          <FormControl>
            <div className={cx('picker', classes.dateTimePicker)}>
              <DatePicker
                keyboard
                autoOk
                clearable
                format={'DD/MM/YYYY'}
                required={required}
                error={error}
                label={dateLabel}
                mask={(value) =>
                  // handle clearing outside if value can be changed outside the component
                  value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                }
                value={dateValue ? dateValue : null}
                onChange={onDateChange}
                disableOpenOnEnter
                minDate={minDate}
              />
              {clearButton === true && (
                <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
              )}
            </div>
            {helpText !== undefined ? (
              <FormHelperText id={id + '-text'} className={helpTextClasses}>
                {helpText}
              </FormHelperText>
            ) : null}
          </FormControl>
        </MuiThemeProvider>
      </Hidden>
    </>
  );
};

export default compose(withStyles(dateTimePickerStyle), connect())(CustomDatePicker);

import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_AUTH, UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
} from '_actions/projectSpecific/types';
import authorizedAbility from '_helpers/Authorization';
import TranslateAbilities from '_helpers/TranslateAbilities';
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from '_helpers/functions';
import {browserHistoryAccessor} from 'App/history';
import React from 'react';
import {SubmissionError} from 'redux-form';
import {Trans} from '@lingui/macro';

export function getUsers(offset = 0, limit = 20, orderBy = null, q = null) {
  return function (dispatch) {
    const usersFilters = {
      usersListFilter: {
        orderBy,
        q,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: usersFilters,
    });
    dispatch({type: FETCH_USERS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.users, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      q: q ? q : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_USERS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function getUser(id) {
  return function (dispatch) {
    dispatch({type: FETCH_USER_REQUEST});
    return HttpClient.get(`${API_ENDPOINT_MAP.users}${id}/`)
      .then((response) => {
        dispatch({
          type: FETCH_USER_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function createUser(formParams) {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.users, {...formParams})
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function editUser(id, formParams) {
  return function (dispatch) {
    return HttpClient.patch(`${API_ENDPOINT_MAP.users}${id}/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function impersonateUser(id) {
  return function (dispatch) {
    return HttpClient.get(`${API_ENDPOINT_MAP.impersonate}${id}/?next=${API_ENDPOINT_MAP.me}`)
      .then((response) => {
        dispatch({
          type: UPDATE_AUTH,
          data: {
            impersonated: response.data,
          },
        });
        fireSuccessToast(<Trans>Impersonating successful {response.data.username}</Trans>);
        authorizedAbility.update(TranslateAbilities(response.data.permissions));
        browserHistoryAccessor.push('/admin/dashboard');
      })
      .catch((error) => {
        fireErrorToast(<Trans>Impersonating failed - {error.response.data.error_message}</Trans>);
      });
  };
}

export function impersonateStop() {
  return function (dispatch, getState) {
    return HttpClient.get(`${API_ENDPOINT_MAP.impersonate}stop/?next=${API_ENDPOINT_MAP.me}`)
      .then((response) => {
        dispatch({
          type: UPDATE_AUTH,
          data: {
            impersonated: {},
          },
        });
        fireSuccessToast(<Trans>Impersonating stopped</Trans>);
        const currentUser = getState().Auth.logged;
        const permissions = currentUser && currentUser.permissions ? currentUser.permissions : [];
        authorizedAbility.update(TranslateAbilities(permissions));
        browserHistoryAccessor.push('/admin/dashboard');
      })
      .catch((error) => {
        fireErrorToast(
          <Trans>Stop of impersonating failed - {error.response.data.error_message}</Trans>
        );
      });
  };
}

export function deleteAccount(user_id) {
  return function () {
    return HttpClient.del(`${API_ENDPOINT_MAP.users}${user_id}/`)
      .then(() => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

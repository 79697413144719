import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP_V2} from '_api/endpoints';
import {
  FETCH_LICENCES_FAIL,
  FETCH_LICENCES_REQUEST,
  FETCH_LICENCES_SUCCESS,
  FETCH_MODULES_FAIL,
  FETCH_MODULES_REQUEST,
  FETCH_MODULES_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';

export function getModules(offset = 0, limit = 20, orderBy = null) {
  return function (dispatch) {
    dispatch({type: FETCH_MODULES_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP_V2.modules, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_MODULES_SUCCESS,
          data: response.data.objects,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        dispatch({type: FETCH_MODULES_FAIL});
        globalApiErrorHandler(error);
      });
  };
}

export function getModuleLicences(customerId, offset = 0, limit = 20, orderBy = null) {
  return function (dispatch) {
    dispatch({type: FETCH_LICENCES_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP_V2.moduleLicences(customerId), {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_LICENCES_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        dispatch({type: FETCH_LICENCES_FAIL});
        globalApiErrorHandler(error);
      });
  };
}

export function postModuleLicence(customerId, data) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP_V2.moduleLicences(customerId), data)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function getOrderableModules(customerId) {
  return function () {
    return HttpClient.get(API_ENDPOINT_MAP_V2.orderableModules(customerId))
      .then((response) => {
        return Promise.resolve(response.data?.objects);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function cancelModuleLicence(customerId, licenceId, data) {
  return function () {
    return HttpClient.patch(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/cancel`,
      data
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function upgradeModuleLicence(customerId, licenceId, data) {
  return function () {
    return HttpClient.patch(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/upgrade`,
      data
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function getUpgradableModules(customerId, licenceId) {
  return function () {
    return HttpClient.get(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/upgradable-modules`
    )
      .then((response) => {
        return Promise.resolve(response.data?.objects);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function downgradeModuleLicence(customerId, licenceId, data) {
  return function () {
    return HttpClient.patch(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/downgrade`,
      data
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function getDowngradableModules(customerId, licenceId) {
  return function () {
    return HttpClient.get(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/downgradable-modules`
    )
      .then((response) => {
        return Promise.resolve(response.data?.objects);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export const convertModuleLicence = (customerId, licenceId) => {
  return function () {
    return HttpClient.patch(
      `${API_ENDPOINT_MAP_V2.moduleLicences(customerId)}/${licenceId}/convert-to-full`
    )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
};

import {
  FETCH_PERMISSIONS_FAIL,
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
} from '_actions/projectSpecific/types';

const initPermissionsState = {
  data: [],
  total: 0,
  loading: false,
};

export const PermissionsReducer = (state = initPermissionsState, action = null) => {
  switch (action.type) {
    case FETCH_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
        loading: false,
      };
    case FETCH_PERMISSIONS_FAIL:
      return {
        ...state,
        error: true,
        loading: true,
      };
    default:
      return state;
  }
};

import Dialog from '_components/global/Dialogs/Dialog';
import GlobalPermissionsForm from '_components/projectSpecific/Forms/GlobalPermissionsForm';
import React from 'react';
import {Trans} from '@lingui/macro';

const InsertPermissionsDialog = ({handleClose, open, onSubmit}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Insert new permission</Trans>}
      content={<GlobalPermissionsForm form="insertPermission" onSubmit={onSubmit} />}
    />
  );
};

export default InsertPermissionsDialog;

import Button from '_components/global/Buttons/Button';
import Dialog from '_components/global/Dialogs/Dialog';
import ModuleLicenceForm from '_components/projectSpecific/Forms/ModuleLicenceForm';
import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {Add} from '@material-ui/icons';

const ModuleLicenceToolbar = ({onSubmit, customerId}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Tooltip title={<Trans>Create new module licence</Trans>}>
        <Button onClick={() => setOpenDialog(true)}>
          <Add />
        </Button>
      </Tooltip>

      <Dialog
        size={'sm'}
        open={openDialog}
        handleClose={handleCloseDialog}
        title={<Trans>Create new module licence</Trans>}
        content={
          <ModuleLicenceForm
            isOpen={openDialog}
            customerId={customerId}
            onSubmit={() => {
              onSubmit();
              handleCloseDialog();
            }}
            form={'createModuleLicence'}
            initialValues={{immediate: true}}
          />
        }
      />
    </>
  );
};

export default ModuleLicenceToolbar;

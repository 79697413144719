import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_AUTH, UPDATE_GLOBAL_SETTINGS_SUCCESS} from '_actions/global/types';
import {AVAILABLE_LANGUAGES} from '_config/configConstants';
import authorizedAbility from '_helpers/Authorization';
import TranslateAbilities from '_helpers/TranslateAbilities';
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from '_helpers/functions';
import {browserHistoryAccessor} from 'App/history';
import React from 'react';
import {SubmissionError} from 'redux-form';
import {Trans} from '@lingui/macro';

//The dispatch and get state function is provided by the Redux-Thunk middleware, we can dispatch actions with it.

export const login = (data) => {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.login, data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('jwtToken', response.data.token);

          HttpClient.get(API_ENDPOINT_MAP.me).then((userResponse) => {
            dispatch({
              type: UPDATE_AUTH,
              data: {
                logged: userResponse.data,
                impersonated: {},
              },
            });

            let lang = userResponse.data.language;
            if (AVAILABLE_LANGUAGES.indexOf(lang) === -1) {
              lang = 'en';
            }
            dispatch({
              type: UPDATE_GLOBAL_SETTINGS_SUCCESS,
              data: {currentLanguage: lang},
            });

            browserHistoryAccessor.push('/');
            authorizedAbility.update(TranslateAbilities(userResponse.data.permissions));
          });
          return response;
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            throw new SubmissionError({_error: 'Incorrect email or password'});
          }
          if (error.response.status === 403) {
            throw new SubmissionError({_error: 'Disabled user'});
          }
        }
        console.error(error);
      });
  };
};

export const logout = () => {
  return function (dispatch) {
    HttpClient.get(API_ENDPOINT_MAP.logout)
      .then((response) => {
        if (response.status === 204) {
          localStorage.removeItem('jwtToken');
          dispatch({
            type: UPDATE_AUTH,
            data: {
              logged: {},
              impersonated: {},
            },
          });
          authorizedAbility.update([]);
          browserHistoryAccessor.push('/auth/sign-in');
        }
        return response;
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
};

export const forgottenPassword = (username) => {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.forgottenPassword, {username})
      .then((response) => {
        if (response.status === 204) {
          fireSuccessToast(<Trans>Link has been sent to your e-mail</Trans>);
        } else {
          fireErrorToast(<Trans>Something went wrong</Trans>);
        }
      })
      .catch(() => {
        fireErrorToast(<Trans>Something went wrong</Trans>);
        return Promise.reject();
      });
  };
};

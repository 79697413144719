import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_VR_S_FAIL,
  FETCH_VR_S_REQUEST,
  FETCH_VR_S_SUCCESS,
  FETCH_VR_T_FAIL,
  FETCH_VR_T_REQUEST,
  FETCH_VR_T_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';

export function postStammnummer(data) {
  return HttpClient.post(API_ENDPOINT_MAP.stammnummer, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      globalApiErrorHandler(error);
      return Promise.resolve(error);
    });
}

export function postTypenscheinnummer(data) {
  return HttpClient.post(API_ENDPOINT_MAP.typenscheinnummer, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      globalApiErrorHandler(error);
      return Promise.resolve(error);
    });
}

export function getStammnummer(offset = 0, limit = 20, orderBy, filters) {
  return function (dispatch) {
    dispatch({type: FETCH_VR_S_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.stammnummer, {
      offset,
      limit,
      order_by: orderBy ? orderBy : null,
      number: filters?.number,
      vehicle_type: filters?.vehicle_type,
    })
      .then((response) => {
        dispatch({type: FETCH_VR_S_SUCCESS, data: response.data});
      })
      .catch((error) => {
        dispatch({type: FETCH_VR_S_FAIL});
        globalApiErrorHandler(error);
      });
  };
}

export function getTypenscheinnummer(offset = 0, limit = 20, orderBy, filters) {
  return function (dispatch) {
    dispatch({type: FETCH_VR_T_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.typenscheinnummer, {
      offset,
      limit,
      order_by: orderBy ? orderBy : null,
      number: filters?.number,
      vehicle_type: filters?.vehicle_type,
    })
      .then((response) => {
        dispatch({type: FETCH_VR_T_SUCCESS, data: response.data});
      })
      .catch((error) => {
        dispatch({type: FETCH_VR_T_FAIL});
        globalApiErrorHandler(error);
      });
  };
}

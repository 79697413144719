import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import {GroupAdd} from '@material-ui/icons';

class CustomerTableToolbar extends Component {
  render() {
    const {classes} = this.props;

    return (
      <Can I="create" a="Customers">
        <Toolbar className={cx(classes.root)}>
          <Tooltip title={<Trans>Insert customer</Trans>}>
            <Button component={Link} to="/admin/customers/new">
              <GroupAdd />
            </Button>
          </Tooltip>
        </Toolbar>
      </Can>
    );
  }
}

CustomerTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonTableToolbarStyle)(CustomerTableToolbar);

import tablePaginationStyle from '_assets/global/jss/components/Tables/tablePaginationStyle';
import React from 'react';
import {MuiThemeProvider} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import TablePagination from '@material-ui/core/TablePagination';

const CustomTablePagination = ({
  count,
  rowsPerPage,
  page,
  changePage,
  changeRowsPerPage,
  rowsPerPageOptions,
}) => (
  <>
    <Hidden smUp implementation="css">
      <MuiThemeProvider theme={tablePaginationStyle}>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page || 0}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={changePage}
          onChangeRowsPerPage={changeRowsPerPage}
        />
      </MuiThemeProvider>
    </Hidden>
    <Hidden smDown implementation="css">
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page || 0}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Hidden>
  </>
);

export default CustomTablePagination;

import {
  downgradeModuleLicence,
  getDowngradableModules,
} from '_actions/projectSpecific/modulesActions';
import Button from '_components/global/Buttons/Button';
import Dialog from '_components/global/Dialogs/Dialog';
import {FormSelectField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {fireSuccessToast, formatExecutionDate} from '_helpers/functions';
import i18nProvider from '_helpers/i18n';
import {modulesStyle} from '_feature/modules/modulesStyle';
import cx from 'classnames';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Trans, t} from '@lingui/macro';
import {Radio, RadioGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';

const DowngradeModuleDialog = ({
  downgradeDialog,
  handleClose,
  classes,
  customerId,
  downgradeModuleLicence,
  getDowngradableModules,
  pristine,
  submitting,
  formData,
  onSubmit,
}) => {
  const [executionTime, setExecutionTime] = useState('immediate');
  const [downgradableModules, setDowngradableModules] = useState([]);

  const moduleLicenceId = downgradeDialog.data?.id;

  const handleSubmit = (e) => {
    e.preventDefault();
    downgradeModuleLicence(customerId, moduleLicenceId, {
      target_module_id: formData.target_module_id,
      execution_time: executionTime,
    }).then(() => {
      fireSuccessToast(<Trans>Module downgraded successfully</Trans>);
      setExecutionTime('immediate');
      onSubmit();
    });
  };

  useEffect(() => {
    if (downgradeDialog.open) {
      getDowngradableModules(customerId, moduleLicenceId).then((res) => {
        if (res)
          setDowngradableModules(
            res.map((module) => ({
              value: module.id,
              label: module.name,
            }))
          );
      });
    }
  }, [downgradeDialog.open, moduleLicenceId, customerId, getDowngradableModules]);

  return (
    <Dialog
      open={downgradeDialog.open}
      handleClose={handleClose}
      title={<Trans>Downgrade module</Trans>}
      maxWidth={'sm'}
      content={
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12}>
              <Field
                label={<Trans>Target module</Trans>}
                htmlFor="target_module_id"
                name="target_module_id"
                id="target_module_id"
                type="select"
                component={FormSelectField}
                options={
                  downgradableModules.length
                    ? downgradableModules
                    : [{value: null, label: i18nProvider._(t`--- No options ---`)}]
                }
              />
            </GridItem>
            <GridItem xs={12}>
              <RadioGroup value={executionTime} onChange={(e) => setExecutionTime(e.target.value)}>
                <FormControlLabel
                  value="immediate"
                  control={<Radio color={'primary'} />}
                  label={<Trans>Downgrade immediately</Trans>}
                />
                <FormControlLabel
                  value="next_evaluation_date"
                  control={<Radio color={'primary'} />}
                  label={
                    <Trans>
                      Downgrade on the next evaluation date
                      {formatExecutionDate(downgradeDialog.data?.next_evaluation_date)}
                    </Trans>
                  }
                />
              </RadioGroup>
            </GridItem>
            <GridItem xs={12}>
              <div className={classes.formActions}>
                <Button
                  onClick={handleClose}
                  disabled={pristine || submitting}
                  className={cx(classes.button)}
                >
                  <Trans>Close</Trans>
                </Button>
                <Button color={'primary'} disabled={pristine || submitting} type={'submit'}>
                  <Trans>Submit</Trans>
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      downgradeModuleLicence,
      getDowngradableModules,
    },
    dispatch
  );
};

export default compose(
  withStyles(modulesStyle),
  reduxForm({
    enableReinitialize: true,
  }),
  connect((store) => {
    return {
      formData: getFormValues('downgradeModuleLicence')(store),
    };
  }, mapDispatchToProps)
)(DowngradeModuleDialog);

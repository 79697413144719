import {deleteAllGeneralFilesStyle} from '_assets/projectSpecific/jss/components/Forms/deleteAllGeneralFilesStyle';
import Button from '_components/global/Buttons/Button';
import {FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {maxLength256, required} from '_helpers/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const DeleteAllGeneralFilesForm = (props) => {
  const {handleSubmit, pristine, handleClose, submitting, classes} = props;

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12}>
          <Field
            name="file_name"
            label={<Trans>File name</Trans>}
            id="file_name"
            component={FormTextField}
            validate={[required, maxLength256]}
            required
          />
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={handleClose}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Close</Trans>
            </Button>
            <Button color={'primary'} disabled={pristine || submitting} type="submit">
              <Trans>Delete</Trans>
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(deleteAllGeneralFilesStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(DeleteAllGeneralFilesForm);

import {TableHeadStyle} from '_assets/global/jss/components/Tables/tableHeadStyle';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

class SimpleTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  renderHeaderCell = (cellData, index) => {
    const {order, classes, orderBy} = this.props;

    const cell = (
      <TableCell
        classes={{root: cx({[classes.actions]: cellData.id === 'actions'}, classes.cell)}}
        key={index}
        sortDirection={order}
      >
        {cellData.sortable && (
          <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
            <TableSortLabel
              active={cellData.order_key ? orderBy === cellData.order_key : orderBy === cellData.id}
              direction={order}
              onClick={this.createSortHandler(
                cellData.order_key ? cellData.order_key : cellData.id
              )}
            >
              <strong className={classes.sorting}>{cellData.label}</strong>
              <br />
              <strong className={classes.sorting}>{cellData.label2}</strong>
            </TableSortLabel>
          </Tooltip>
        )}
        {!cellData.sortable && (
          <>
            <span>{cellData.label}</span>
            <br />
            <span>{cellData.label2}</span>
          </>
        )}
      </TableCell>
    );

    if (cellData.pemissions) {
      return (
        <Can I={cellData.permissions.action} a={cellData.permissions.subject}>
          {cell}
        </Can>
      );
    }
    return cell;
  };

  render() {
    const {rows} = this.props;

    return (
      <TableHead>
        <TableRow>{rows.map((row, index) => this.renderHeaderCell(row, index), this)}</TableRow>
      </TableHead>
    );
  }
}

SimpleTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default withStyles(TableHeadStyle)(SimpleTableHead);

import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_CARTS_REQUEST,
  FETCH_CARTS_SUCCESS,
  FETCH_CART_LOCK_INFO_REQUEST,
  FETCH_CART_LOCK_INFO_SUCCESS,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
} from '_actions/projectSpecific/types';
import {FORMAT_EXTENSION_MAP} from '_config/configConstants';
import {globalApiErrorHandler} from '_helpers/functions';
import moment from 'moment-timezone';
import {SubmissionError} from 'redux-form';

export function getCarts(
  offset = 0,
  limit = 20,
  orderBy = null,
  customerNo = null,
  from = null,
  to = null,
  status = null,
  ordered = null
) {
  return function (dispatch) {
    const cartFilters = {
      cartListFilter: {
        orderBy,
        customerNo,
        from,
        to,
        status,
        ordered,
      },
    };

    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: cartFilters,
    });

    dispatch({type: FETCH_CARTS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.carts, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      customer_no: customerNo ? customerNo : null,
      from: from instanceof moment ? from.format() : null,
      to: to instanceof moment ? to.format() : null,
      status__in: status && status !== 'all' ? status : null,
      ordered: ordered === null || ordered === '' ? null : ordered,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CARTS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function getFilteredCartsExport(
  customerNo = null,
  from = null,
  to = null,
  status = null,
  format = null
) {
  return function () {
    const formatExtension = FORMAT_EXTENSION_MAP[format] || 'csv';
    return HttpClient.get(
      API_ENDPOINT_MAP.carts,
      {
        customer_no: customerNo && customerNo,
        from: from && from.format(),
        to: to && to.format(),
        status__in: status && status,
        format: formatExtension && formatExtension,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: format,
        },
      }
    )
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getCart(cart_id) {
  return function (dispatch) {
    dispatch({type: FETCH_CART_REQUEST});
    return HttpClient.get(`${API_ENDPOINT_MAP.carts}${cart_id}/`)
      .then((response) => {
        dispatch({
          type: FETCH_CART_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

// used in Carts
export function updateCart(cart_id, formParams) {
  return function () {
    return HttpClient.patch(`${API_ENDPOINT_MAP.carts}${cart_id}/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

// used in Orders
export function sendCart(cart_id, formParams) {
  return function () {
    return HttpClient.patch(`${API_ENDPOINT_MAP.carts}${cart_id}/sales-update/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function lockCarts(cart_ids, is_locked) {
  return function () {
    return HttpClient.post(`${API_ENDPOINT_MAP.carts}lock/`, {
      cart_ids: cart_ids,
      is_locked: is_locked,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getCartLockInfo(cart_id) {
  return function (dispatch) {
    dispatch({type: FETCH_CART_LOCK_INFO_REQUEST});
    return HttpClient.get(`${API_ENDPOINT_MAP.carts}${cart_id}/lock/`)
      .then((response) => {
        dispatch({
          type: FETCH_CART_LOCK_INFO_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function deliverCarts(cart_ids, send_email) {
  return function () {
    return HttpClient.post(`${API_ENDPOINT_MAP.carts}deliver/`, {
      cart_ids: cart_ids,
      send_email: send_email,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function exportCarts(cart_ids, format) {
  return function () {
    const formatExtension = FORMAT_EXTENSION_MAP[format] || 'csv';
    return HttpClient.post(
      `${API_ENDPOINT_MAP.carts}export/`,
      {
        cart_ids: cart_ids,
      },
      {
        params: {
          format: formatExtension,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: format,
        },
      }
    )
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function exportCartToXLS(id) {
  return function () {
    return HttpClient.get(
      `${API_ENDPOINT_MAP.carts}${id}/export/`,
      {}, // empty data params
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arraybuffer',
      }
    )
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function orderCarts(cart_ids, formParams) {
  return function () {
    return HttpClient.post(`${API_ENDPOINT_MAP.carts}order/`, {
      cart_ids: cart_ids,
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_NOTIFICATIONS} from '_actions/global/types';
import {globalApiErrorHandler} from '_helpers/functions';

export function getNotifications() {
  return function (dispatch) {
    return HttpClient.get(API_ENDPOINT_MAP.notifications)
      .then((response) => {
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          data: response.data,
          meta: {
            favicon: response.data.carts,
          },
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

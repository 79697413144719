import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import React from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Add} from '@material-ui/icons';

const VehicleReferencesTableToolbar = ({classes, onAdd}) => {
  return (
    <Can I="create" a="VehicleReferences">
      <Toolbar className={cx(classes.root)}>
        <Tooltip title={<Trans>Insert vehicle reference</Trans>}>
          <Button onClick={onAdd}>
            <Add />
          </Button>
        </Tooltip>
      </Toolbar>
    </Can>
  );
};

export default withStyles(commonTableToolbarStyle)(VehicleReferencesTableToolbar);

import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {ReactComponent as Logo} from '_assets/projectSpecific/img/ec24-logo-dark.svg';
import RegularButton from '_components/global/Buttons/Button';
import Footer from '_components/global/Footer/Footer';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import activationStyle from '_components/projectSpecific/Activation/activationStyle';
import {AVAILABLE_LANGUAGES} from '_config/configConstants';
import {validatePasswordPolicies, validateRepeatingPassword} from '_helpers/FieldLevelValidation';
import {fireErrorToast, fireSuccessToast} from '_helpers/functions';
import i18nProvider from '_helpers/i18n';
import {browserHistoryAccessor} from 'App/history';
import moment from 'moment';
import {parse} from 'query-string';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans, t} from '@lingui/macro';
import {InputBase} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {activationErrorHandle} from './helpers';

const ActivationResetPasswordPage = (props) => {
  const {classes, updateGlobalSettings} = props;
  const {username, language, valid_until, token} = parse(window.location.search);

  const [submitting, setSubmitting] = useState(false);

  const [isValid, setIsValid] = useState(true);

  const [values, setValues] = useState({
    password: '',
    passwordError: '',
    repeatPassword: '',
    repeatPasswordError: '',
    showPassword: false,
    showRepeatPassword: false,
  });

  function handleClickShowPassword(type) {
    setValues({...values, [type]: !values[type]});
  }

  function resetPassword() {
    setSubmitting(true);
    return HttpClient.post(`${API_ENDPOINT_MAP.activationResetPassword}`, {
      username: username,
      token: token,
      new_password: values.repeatPassword,
    })
      .then((res) => {
        if (res.data?.redirect_url) {
          fireSuccessToast(
            <Trans>Password has been changed. You will be redirected to the new catalogue.</Trans>,
            {autoClose: 3000, onClose: () => window.location.replace(res.data.redirect_url)}
          );
        } else {
          fireErrorToast(<Trans>Something went wrong</Trans>);
        }
      })
      .catch((err) => {
        activationErrorHandle(err);
        setSubmitting(false);
      });
  }

  function handlePasswordChange(name, value) {
    let stateUpdate = {[name]: value};
    if (name === 'password') {
      stateUpdate.passwordError = !!validatePasswordPolicies(value);
      if (values.repeatPassword.length) {
        stateUpdate.repeatPasswordError = !!validateRepeatingPassword(
          stateUpdate.repeatPasswordError,
          {password: value}
        );
      }
    }
    if (name === 'repeatPassword') {
      stateUpdate.repeatPasswordError = !!validateRepeatingPassword(value, values);
    }
    setValues((prevState) => ({...prevState, ...stateUpdate}));
  }

  function requestNewLink() {
    const params = new URLSearchParams();
    language && params.set('language', language);
    username && params.set('username', username);
    const newSearch = params.toString()?.length ? `?${params.toString()}` : '';
    browserHistoryAccessor.push(`/activation${newSearch}`);
  }

  useEffect(() => {
    if (valid_until) {
      const split = valid_until.split(' ');
      let preparedMoment;
      if (split.length === 3) {
        preparedMoment = moment(`${split[0]}T${split[1].substring(0, 8)}+${split[2] || '00:00'}`);
      } else {
        // fallback in date is not in correct format
        preparedMoment = moment(valid_until);
      }

      if (preparedMoment.isBefore(moment())) {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, [valid_until]);

  useEffect(() => {
    if (AVAILABLE_LANGUAGES.includes(language)) {
      updateGlobalSettings({currentLanguage: language});
    } else {
      // if not specified, set language as 'de'
      updateGlobalSettings({currentLanguage: 'de'});
    }
  }, [language, updateGlobalSettings]);

  return (
    <GridContainer
      direction={'column'}
      className={classes.activationContainer}
      alignContent={'center'}
      alignItems={'center'}
      justify={'center'}
    >
      <GridContainer
        direction={'row'}
        justify={'center'}
        className={classes.formCard}
        alignContent={'center'}
      >
        {submitting ? (
          <CircularProgress className={classes.loadingCircle} thickness={5} size={100} />
        ) : (
          <GridItem
            noPadding={true}
            container
            xs={12}
            style={{
              padding: '30px',
              border: '1px solid #dddddd',
              boxShadow: '0px 0px 16px 2px rgba(176,176,176,0.8)',
            }}
          >
            <GridItem xs={12} style={{marginBottom: '30px'}}>
              <Logo />
            </GridItem>
            {isValid ? (
              <>
                <GridItem
                  xs={12}
                  style={{color: 'black', fontSize: '16px', fontFamily: 'SharpGrotesk'}}
                >
                  <Trans>To change your password, please enter a new password below.</Trans>
                </GridItem>
                <GridItem xs={12} noPadding={true} style={{margin: '40px 0 10px'}}>
                  <FormControl fullWidth={true} error={!!values.passwordError}>
                    <InputBase
                      margin={'dense'}
                      className={classes.activationField}
                      type={values.showPassword ? 'text' : 'password'}
                      name={'password'}
                      id={'password'}
                      value={values.password}
                      onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                      placeholder={i18nProvider._(t`New password`)}
                      disabled={submitting}
                      endAdornment={
                        <InputAdornment position="end" style={{marginRight: '16px'}}>
                          <IconButton
                            onClick={() => handleClickShowPassword('showPassword')}
                            style={{padding: 0}}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText style={{fontFamily: 'Campton'}}>
                      {!!values.passwordError && validatePasswordPolicies(values.password, values)}
                    </FormHelperText>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} noPadding={true} style={{margin: '10px 0'}}>
                  <FormControl fullWidth={true} error={!!values.repeatPasswordError}>
                    <InputBase
                      fullWidth={true}
                      margin={'dense'}
                      className={classes.activationField}
                      type={values.showRepeatPassword ? 'text' : 'password'}
                      name={'repeatPassword'}
                      id={'repeatPassword'}
                      value={values.repeatPassword}
                      onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                      placeholder={i18nProvider._(t`Re-enter password`)}
                      disabled={submitting}
                      endAdornment={
                        <InputAdornment position="end" style={{marginRight: '16px'}}>
                          <IconButton
                            onClick={() => handleClickShowPassword('showRepeatPassword')}
                            style={{padding: 0}}
                          >
                            {values.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText style={{fontFamily: 'Campton'}}>
                      {!!values.repeatPasswordError &&
                        validateRepeatingPassword(values.repeatPassword, values)}
                    </FormHelperText>
                  </FormControl>
                </GridItem>

                <RegularButton
                  disabled={
                    !!(
                      !values.password.length ||
                      !values.repeatPassword.length ||
                      values.passwordError ||
                      values.repeatPasswordError ||
                      submitting
                    )
                  }
                  fullWidth={true}
                  className={classes.activationButton}
                  onClick={resetPassword}
                >
                  {submitting ? (
                    <CircularProgress className={classes.activationLoading} color="inherit" />
                  ) : (
                    <Trans>Reset password</Trans>
                  )}
                </RegularButton>
              </>
            ) : (
              <>
                <GridItem
                  xs={12}
                  style={{color: 'black', fontSize: '20px', fontFamily: 'SharpGrotesk'}}
                >
                  <Trans>The link has expired. Please request a new one.</Trans>
                </GridItem>
                <RegularButton
                  fullWidth={true}
                  className={classes.activationButton}
                  onClick={requestNewLink}
                >
                  <Trans>Request new link</Trans>
                </RegularButton>
              </>
            )}
          </GridItem>
        )}
      </GridContainer>

      <GridContainer direction={'row'} className={classes.footerContainer}>
        <Footer />
      </GridContainer>
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({updateGlobalSettings}, dispatch);
};

export default compose(
  connect((store) => {
    return {};
  }, mapDispatchToProps),
  withStyles(activationStyle)
)(ActivationResetPasswordPage);

import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {getModuleLicences} from '_actions/projectSpecific/modulesActions';
import {usersPageStyle} from '_assets/projectSpecific/jss/views/usersPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import Loader from '_components/projectSpecific/Loader/Loader';
import ModuleLicenceToolbar from '_components/projectSpecific/Toolbars/ModuleLicenceToolbar';
import {DATE_FORMAT, DATE_FORMAT_DB} from '_config/configConstants';
import {LICENCE_STAUSES} from '_config/constants';
import {getOrderByV2, prepareSortingState} from '_helpers/functions';
import CancelModuleDialog from '_feature/modules/dialogs/CancelModuleDialog';
import ConvertModuleDialog from '_feature/modules/dialogs/ConvertModuleDialog';
import DowngradeModuleDialog from '_feature/modules/dialogs/DowngradeModuleDialog';
import UpgradeModuleDialog from '_feature/modules/dialogs/UpgradeModuleDialog';
import cx from 'classnames';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Block, Sync, VerticalAlignBottom, VerticalAlignTop} from '@material-ui/icons';

const ModuleLicencesTable = ({
  classes,
  customerId,
  tabValue,
  globalSettings,
  updateGlobalSettings,
  getModuleLicences,
  licences,
  loading,
  total,
  initLicences,
}) => {
  const [cancelDialog, setCancelDialog] = useState({data: null, open: false});
  const [upgradeDialog, setUpgradeDialog] = useState({data: null, open: false});
  const [downgradeDialog, setDowngradeDialog] = useState({data: null, open: false});
  const [convertDialog, setConvertDialog] = useState({data: null, open: false});

  const [order, setOrder] = useState({
    order: 'desc',
    orderBy: 'created_at',
  });

  const [page, setPage] = useState(0);

  const rows = [
    {
      id: 'id',
      label: <Trans>Id</Trans>,
      sortable: false,
    },
    {
      id: 'module__name',
      label: <Trans>Module name</Trans>,
      sortable: false,
    },
    {
      id: 'module__kind',
      label: <Trans>Kind</Trans>,
      sortable: false,
    },
    {
      id: 'active_from',
      label: <Trans>Active from</Trans>,
      sortable: false,
    },
    {
      id: 'next_evaluation_date',
      label: <Trans>Next evaluation date</Trans>,
      sortable: false,
    },
    {
      id: 'active_until',
      label: <Trans>Active until</Trans>,
      sortable: false,
    },
    {
      id: 'status',
      label: <Trans>Status</Trans>,
      sortable: false,
    },
    {
      id: 'actions',
      label: <Trans>Actions</Trans>,
      sortable: false,
    },
  ];

  const handleOpenCancelDialog = (licence) => {
    setCancelDialog({data: licence, open: true});
  };

  const handleCloseCancelDialog = () => {
    setCancelDialog({data: null, open: false});
  };

  const handleOpenUpgradeDialog = (licence) => {
    setUpgradeDialog({data: licence, open: true});
  };

  const handleCloseUpgradeDialog = () => {
    setUpgradeDialog({data: null, open: false});
  };

  const handleOpenDowngradeDialog = (licence) => {
    setDowngradeDialog({data: licence, open: true});
  };

  const handleCloseDowngradeDialog = () => {
    setDowngradeDialog({data: null, open: false});
  };

  const handleOpenConvertDialog = (licence) => {
    setConvertDialog({data: licence, open: true});
  };

  const handleCloseConvertDialog = () => {
    setConvertDialog({data: null, open: false});
  };

  const handleRequestSort = (event, property) => {
    setOrder(prepareSortingState(order.orderBy, order.order, property));
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    updateGlobalSettings({itemsPerPage: event.target.value});
    setPage(0);
  };

  const fetchModuleLicences = useCallback(() => {
    getModuleLicences(
      customerId,
      page * globalSettings.itemsPerPage,
      globalSettings.itemsPerPage,
      getOrderByV2(order.orderBy, order.order)
    );
  }, [getModuleLicences, customerId, page, order, globalSettings.itemsPerPage]);

  useEffect(() => {
    // in case of changing order or number of tabs in customer edit, check if tabValue is correct
    if (initLicences) fetchModuleLicences();
  }, [fetchModuleLicences, initLicences]);

  const isActiveAndNotDemo = (licence) =>
    licence.status === 'ACTIVE' && licence.module?.is_demo === false;

  const isActiveAndDemo = (licence) =>
    licence.status === 'ACTIVE' && licence.module?.is_demo === true;

  return (
    <>
      <GridContainer>
        <GridItem xs={12} align="right">
          <ModuleLicenceToolbar
            customerId={customerId}
            onSubmit={() => {
              setPage(0);
              fetchModuleLicences();
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.tableWrapper}>
            {loading && <Loader />}
            <Table className={classes.table} aria-labelledby="tableTitle">
              <SimpleTableHead
                order={order.order}
                orderBy={order.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={licences.length}
                rows={rows}
              />
              <TableBody>
                {licences.map((licence, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell className={classes.cell}>{licence?.id}</TableCell>
                    <TableCell className={classes.cell}>
                      {licence.module?.name}
                      {licence.module?.is_demo ? (
                        <Chip className={classes.demoChip} label={<Trans>Demo</Trans>} />
                      ) : null}
                    </TableCell>
                    <TableCell className={classes.cell}>{licence.module?.kind}</TableCell>
                    <TableCell className={classes.cell}>
                      {licence.active_from
                        ? moment(licence.active_from, DATE_FORMAT_DB).format(DATE_FORMAT)
                        : '---'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {licence.next_evaluation_date
                        ? moment(licence.next_evaluation_date, DATE_FORMAT_DB).format(DATE_FORMAT)
                        : '---'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {licence.active_until
                        ? moment(licence.active_until, DATE_FORMAT_DB).format(DATE_FORMAT)
                        : '---'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {LICENCE_STAUSES.find((status) => status.value === licence.status)?.label}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <div className={cx('actions-right', classes.actions)}>
                        {/* CANCEL */}
                        <Tooltip
                          title={
                            licence.status === 'ACTIVE' ? (
                              <Trans>Cancel module</Trans>
                            ) : (
                              <Trans>This module can't be canceled</Trans>
                            )
                          }
                        >
                          <span>
                            <BaseActionsButton
                              color={'danger'}
                              onClick={() => handleOpenCancelDialog(licence)}
                              disabled={licence.status !== 'ACTIVE'}
                            >
                              <Block />
                            </BaseActionsButton>
                          </span>
                        </Tooltip>

                        {/* UPGRADE */}
                        <Tooltip
                          title={
                            isActiveAndNotDemo(licence) ? (
                              <Trans>Upgrade module</Trans>
                            ) : (
                              <Trans>This module can't be upgraded</Trans>
                            )
                          }
                        >
                          <span>
                            <BaseActionsButton
                              color={'success'}
                              onClick={() => handleOpenUpgradeDialog(licence)}
                              disabled={!isActiveAndNotDemo(licence)}
                            >
                              <VerticalAlignTop />
                            </BaseActionsButton>
                          </span>
                        </Tooltip>

                        {/* DOWNGRADE */}
                        <Tooltip
                          title={
                            isActiveAndNotDemo(licence) ? (
                              <Trans>Downgrade module</Trans>
                            ) : (
                              <Trans>This module can't be downgraded</Trans>
                            )
                          }
                        >
                          <span>
                            <BaseActionsButton
                              color={'warning'}
                              onClick={() => handleOpenDowngradeDialog(licence)}
                              disabled={!isActiveAndNotDemo(licence)}
                            >
                              <VerticalAlignBottom />
                            </BaseActionsButton>
                          </span>
                        </Tooltip>

                        {/* CONVERT */}
                        <Tooltip
                          title={
                            isActiveAndDemo(licence) ? (
                              <Trans>Convert to full module</Trans>
                            ) : (
                              <Trans>This module can't be converted</Trans>
                            )
                          }
                        >
                          <span>
                            <BaseActionsButton
                              color={'primary'}
                              onClick={() => handleOpenConvertDialog(licence)}
                              disabled={!isActiveAndDemo(licence)}
                            >
                              <Sync />
                            </BaseActionsButton>
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <CustomTablePagination
            rowsPerPageOptions={globalSettings.rowsPerPageOptions}
            rowsPerPage={globalSettings.itemsPerPage}
            count={total}
            page={page}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
          />
        </GridItem>
      </GridContainer>

      <CancelModuleDialog
        cancelDialog={cancelDialog}
        handleClose={handleCloseCancelDialog}
        customerId={customerId}
        onSubmit={() => {
          fetchModuleLicences();
          handleCloseCancelDialog();
        }}
      />

      <UpgradeModuleDialog
        upgradeDialog={upgradeDialog}
        handleClose={handleCloseUpgradeDialog}
        customerId={customerId}
        onSubmit={() => {
          fetchModuleLicences();
          handleCloseUpgradeDialog();
        }}
        form={'upgradeModuleLicence'}
      />

      <DowngradeModuleDialog
        downgradeDialog={downgradeDialog}
        handleClose={handleCloseDowngradeDialog}
        customerId={customerId}
        onSubmit={() => {
          fetchModuleLicences();
          handleCloseDowngradeDialog();
        }}
        form={'downgradeModuleLicence'}
      />

      <ConvertModuleDialog
        convertDialog={convertDialog}
        handleClose={handleCloseConvertDialog}
        customerId={customerId}
        onSubmit={() => {
          fetchModuleLicences();
          handleCloseConvertDialog();
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getModuleLicences,
      updateGlobalSettings,
    },
    dispatch
  );
};

export default compose(
  withStyles(usersPageStyle),
  connect((store) => {
    return {
      globalSettings: store.GlobalSettings,
      licences: store.Modules.licences,
      loading: store.Modules.licencesLoading,
      total: store.Modules.licencesTotal,
    };
  }, mapDispatchToProps)
)(ModuleLicencesTable);

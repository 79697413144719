import TranslateAbilities from '_helpers/TranslateAbilities';
import {actualUserSelector} from '_helpers/functions';
import store from 'App/store';
import {Ability} from '@casl/ability';

const state = store.getState();
const currentUser = actualUserSelector(state.Auth);

const permissions = currentUser && currentUser.permissions ? currentUser.permissions : [];

const authorizedAbility = new Ability(TranslateAbilities(permissions));

export default authorizedAbility;

import {updateLocalLoader} from '_actions/global/loaderActions';
import {getWaitingOrders, postWaitingOrders} from '_actions/projectSpecific/configActions';
import {
  copyFlyers,
  copyGeneralFiles,
  copyPricelists,
  deleteAllFlyers,
  deleteAllGeneralFiles,
  deleteAllPricelists,
} from '_actions/projectSpecific/settingsActions';
import {editUserPageStyle} from '_assets/projectSpecific/jss/views/editUserPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import DeleteAllGeneralFilesForm from '_components/projectSpecific/Forms/DeleteAllGeneralFilesForm';
import CommonTabs from '_components/projectSpecific/Tabs/CommonTabs';
import ConfigurationTab from '_components/projectSpecific/Tabs/ConfigurationTab';
import {fireErrorToast, fireSuccessToast} from '_helpers/functions';
import cx from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import {Grid} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import {withStyles} from '@material-ui/core/styles';
import {Settings} from '@material-ui/icons';

class ApplicationSettingsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      generalFileName: '',
      fileName: '',
      deleteAllFlyersFile: '',
      isDeleteFilesDialogVisible: false,
      isDeleteFlyersDialogVisible: false,
      waitingOrdersLoading: false,
      managementData: [
        {
          id: 0,
          description: <Trans>Delete all general files with given filename</Trans>,
          action: this.toggleDeleteGeneralFilesWithNameDialog,
        },
        {
          id: 1,
          description: <Trans>Delete pricelists in customers' folders</Trans>,
          action: this.handleDeleteAllPricelists,
        },
        {
          id: 2,
          description: <Trans>Delete flyer files with given filename</Trans>,
          action: this.toggleDeleteAllFlyersWithNameDialog,
        },
        {
          id: 3,
          description: <Trans>Copy pricelists to customers' price folders</Trans>,
          action: this.handleCopyPricelists,
        },
        {
          id: 4,
          description: <Trans>Copy general files to customers' general folders</Trans>,
          action: this.handleCopyGeneralFiles,
        },
        {
          id: 5,
          description: <Trans>Copy flyers to customers' folders</Trans>,
          action: this.handleCopyFlyers,
        },
      ],
    };
    // if (authorizedAbility.cannot("update", "Users")) {
    //   browserHistoryAccessor.push("/admin/404")
    // }
  }

  handleRunDeleteGeneralFilesWithName = (e) => {
    e.preventDefault();
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .deleteAllGeneralFiles(this.props.fileName)
      .then((res) => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion of file {this.props.fileName} failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  toggleDeleteGeneralFilesWithNameDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDeleteFilesDialogVisible: !prevState.isDeleteFilesDialogVisible,
    }));
  };

  handleDeleteAllPricelists = () => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .deleteAllPricelists()
      .then(() => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch(() => {
        fireErrorToast(<Trans>Deletion of pricelists failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  handleRunDeleteFlyersWithName = (e) => {
    e.preventDefault();
    this.props.updateLocalLoader({localLoading: true});

    this.props
      .deleteAllFlyers(this.props.deleteAllFlyersFile)
      .then((res) => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion of flyer {this.props.deleteAllFlyersFile} failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  toggleDeleteAllFlyersWithNameDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDeleteFlyersDialogVisible: !prevState.isDeleteFlyersDialogVisible,
    }));
  };

  handleCopyPricelists = () => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .copyPricelists()
      .then(() => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch(() => {
        fireErrorToast(<Trans>Copy of pricelists failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  handleCopyGeneralFiles = () => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .copyGeneralFiles()
      .then(() => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch(() => {
        fireErrorToast(<Trans>Copy of general files failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  handleCopyFlyers = () => {
    this.props.updateLocalLoader({localLoading: true});
    this.props
      .copyFlyers()
      .then(() => {
        fireSuccessToast(<Trans>Management command has been put into the queue.</Trans>);
      })
      .catch(() => {
        fireErrorToast(<Trans>Copy of flyers failed.</Trans>);
      })
      .finally(() => {
        this.props.updateLocalLoader({localLoading: false});
      });
  };

  handleSettingsTabChange = (event, tabValue) => {
    if (tabValue === 0) {
      this.props.getWaitingOrders();
    }
    this.setState({tabValue});
  };

  handleSettingsTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  prepareTabs = () => [
    <Tab key="management" label={<Trans>Management</Trans>} />,
    <Tab key="configuration" label={<Trans>Configuration</Trans>} />,
  ];

  handlePostWaitingOrders = () => {
    this.setState({waitingOrdersLoading: true});
    this.props
      .postWaitingOrders({cart_ids: []})
      .then((res) => {
        this.toastId = fireSuccessToast(
          <Trans>Management command has been put into the queue.</Trans>
        );
        this.setState({waitingOrdersLoading: false});
        this.props.getWaitingOrders();
      })
      .catch((err) => {
        this.toastId = fireErrorToast(<Trans>Sending of waiting orders failed</Trans>);
        this.setState({waitingOrdersLoading: true});
        this.props.getWaitingOrders();
      });
  };

  componentDidMount() {
    this.props.getWaitingOrders().then((res) => {});
  }

  render() {
    const {classes, theme, waitingOrders, waitingOrdersLoading} = this.props;
    const sendWaitingOrdersDisabled = waitingOrders?.can_be_sent_to_oracle
      ? waitingOrders?.carts_to_be_sent !== 0
      : false;
    const rowBackgroundCanSendOrders =
      waitingOrders?.carts_to_be_sent !== 0
        ? waitingOrders?.can_be_sent_to_oracle
          ? 'green'
          : 'red'
        : false;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon className={classes.root}>
              <CardIcon color="blue">
                <Settings />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Application settings</Trans>
              </h4>
              <div className={classes.tabBar}>
                <AppBar position="static" color="default">
                  <CommonTabs value={this.state.tabValue} onChange={this.handleSettingsTabChange}>
                    {this.prepareTabs()}
                  </CommonTabs>
                </AppBar>
              </div>
            </CardHeader>
            <CardBody>
              <Dialog
                open={this.state.isDeleteFilesDialogVisible}
                handleClose={this.toggleDeleteGeneralFilesWithNameDialog}
                title={<Trans>Delete all general files with given filename</Trans>}
                maxWidth={false}
                content={
                  <DeleteAllGeneralFilesForm
                    form="deleteAllGeneralFiles"
                    handleSubmit={this.handleRunDeleteGeneralFilesWithName}
                    handleClose={this.toggleDeleteGeneralFilesWithNameDialog}
                  />
                }
              />
              <Dialog
                open={this.state.isDeleteFlyersDialogVisible}
                handleClose={this.toggleDeleteAllFlyersWithNameDialog}
                title={<Trans>Delete flyer files with given filename</Trans>}
                maxWidth={false}
                content={
                  <DeleteAllGeneralFilesForm
                    form="deleteAllFlyers"
                    handleSubmit={this.handleRunDeleteFlyersWithName}
                    handleClose={this.toggleDeleteAllFlyersWithNameDialog}
                  />
                }
              />
              <SwipeableView
                index={this.state.tabValue}
                onChangeIndex={this.handleSettingsTabChangeIndex}
              >
                <TabContainer dir={theme.direction}>
                  <div className={classes.configuration}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={cx(classes.fieldPadding, classes.title)}>
                          <Trans>Files & Download section</Trans>
                        </div>
                      </Grid>
                      {this.state.managementData.map((row, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            xs={10}
                            container
                            alignItems={'center'}
                            className={classes.gridRowBorder}
                          >
                            {row.description}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            container
                            justify={'flex-end'}
                            className={classes.gridRowBorder}
                          >
                            <Button size="sm" onClick={row.action}>
                              <Trans>Execute</Trans>
                            </Button>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className={cx(classes.fieldPadding, classes.title)}>
                          <Trans>Order management section</Trans>
                        </div>
                      </Grid>
                      {this.state.waitingOrdersLoading ? (
                        <GridItem xs={12}>
                          <div className={classes.importProgressBar}>
                            <div className={classes.importProgressText}>
                              <Trans>Sending of waiting orders is in progress</Trans>
                            </div>
                            <LinearProgress />
                          </div>
                        </GridItem>
                      ) : (
                        <>
                          <Grid item xs={10} container alignItems={'center'}>
                            <Trans>Send waiting orders</Trans>
                            &nbsp;&ndash;&nbsp;
                            <span className={classes.totalWaitingOrdersText}>
                              <Trans>total waiting orders</Trans>&nbsp;
                              {waitingOrders?.carts_to_be_sent}
                            </span>
                            {!waitingOrdersLoading && rowBackgroundCanSendOrders && (
                              <Chip
                                className={cx(
                                  rowBackgroundCanSendOrders === 'green'
                                    ? classes.lightGreenBackground
                                    : rowBackgroundCanSendOrders === 'red'
                                    ? classes.lightRedBackground
                                    : ''
                                )}
                                label={
                                  rowBackgroundCanSendOrders === 'red' ? (
                                    <Trans>Disadmin is in offline mode, orders can't be sent</Trans>
                                  ) : (
                                    <Trans>Disadmin is in online mode, orders can be sent</Trans>
                                  )
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={2} container justify={'flex-end'}>
                            <Button
                              disabled={!sendWaitingOrdersDisabled}
                              size="sm"
                              onClick={this.handlePostWaitingOrders}
                            >
                              <Trans>Execute</Trans>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                  <ConfigurationTab />
                </TabContainer>
              </SwipeableView>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const deleteAllGeneralFilesSelector = formValueSelector('deleteAllGeneralFiles');
const deleteAllFlyersSelector = formValueSelector('deleteAllFlyers');

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      deleteAllGeneralFiles,
      deleteAllPricelists,
      deleteAllFlyers,
      copyGeneralFiles,
      copyPricelists,
      copyFlyers,
      getWaitingOrders,
      postWaitingOrders,
    },
    dispatch
  );
};

export default compose(
  withStyles(editUserPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      fileName: deleteAllGeneralFilesSelector(store, 'file_name'),
      deleteAllFlyersFile: deleteAllFlyersSelector(store, 'file_name'),
      waitingOrders: store.Config.waitingOrders,
      waitingOrdersLoading: store.Config.loading,
    };
  }, mapDispatchToProps)
)(ApplicationSettingsPage);

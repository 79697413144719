import {convertModuleLicence} from '_actions/projectSpecific/modulesActions';
import Button from '_components/global/Buttons/Button';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {fireSuccessToast} from '_helpers/functions';
import {modulesStyle} from '_feature/modules/modulesStyle';
import cx from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

const ConvertModuleDialog = ({
  convertDialog,
  handleClose,
  classes,
  customerId,
  convertModuleLicence,
  onSubmit,
}) => {
  const handleSubmit = () => {
    convertModuleLicence(customerId, convertDialog.data?.id).then(() => {
      fireSuccessToast(<Trans>Module converted successfully</Trans>);
      onSubmit();
    });
  };

  return (
    <Dialog
      open={convertDialog.open}
      handleClose={handleClose}
      title={<Trans>Convert to full module</Trans>}
      maxWidth={'sm'}
      content={
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant={'body1'} className={classes.warningTitle}>
              <Trans>
                Are you sure you want to convert {convertDialog.data?.module?.name} module to full
                licence? This change will happen on the next evaluation date.
              </Trans>
            </Typography>
          </GridItem>

          <GridItem xs={12}>
            <div className={classes.formActions}>
              <Button onClick={handleClose} className={cx(classes.button)}>
                <Trans>Close</Trans>
              </Button>
              <Button color={'primary'} onClick={handleSubmit}>
                <Trans>Submit</Trans>
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      convertModuleLicence,
    },
    dispatch
  );
};

export default compose(
  withStyles(modulesStyle),
  connect((store) => {
    return {};
  }, mapDispatchToProps)
)(ConvertModuleDialog);

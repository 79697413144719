import {
  detailCartFormStyle,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/detailCartFormStyle';
import Button from '_components/global/Buttons/Button';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import AdditionalCartItemRow from '_components/projectSpecific/Tables/AdditionalCartItemRow';
import OrderItemRow from '_components/projectSpecific/Tables/OrderItemRow';
import {Can} from '_helpers/AuthorizedAbilityContext';
import React from 'react';
import compose from 'recompose/compose';
import {reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

const DetailOrderForm = (props) => {
  const {handleSendOrderToRhiag, handleClose, handleDownloadXLS, handleSetAsOrdered} = props;
  const {items, rows1, classes, condition, deliveryOptions, canBeSentToOracle, ordered} = props;

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <MuiThemeProvider theme={tableHeadTheme}>
            <SimpleTableHead rowCount={items.length} rows={rows1} />
          </MuiThemeProvider>
          <TableBody>
            {items.map((item, index) => {
              return item.additional_item !== null ? (
                <>
                  <OrderItemRow
                    cartItem={item}
                    cartIndex={index}
                    deliveryOptions={deliveryOptions}
                  />
                  <AdditionalCartItemRow cartItem={item.additional_item} cartIndex={index} />
                </>
              ) : (
                <OrderItemRow
                  key={index}
                  cartItem={item}
                  cartIndex={index}
                  deliveryOptions={deliveryOptions}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div className={classes.saveBtn}>
        <Button color="danger" onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
        {condition && (
          <>
            {canBeSentToOracle && !ordered && (
              <Can I="update" a="Order">
                <Tooltip title={<Trans>Send order to RHIAG</Trans>}>
                  <Button color="primary" onClick={handleSendOrderToRhiag}>
                    <Trans>Send order to RHIAG</Trans>
                  </Button>
                </Tooltip>
              </Can>
            )}
            <Can I="read" a="Order">
              <Tooltip title={<Trans>Download order in XLS format</Trans>}>
                <Button color="primary" onClick={handleDownloadXLS}>
                  <Trans>Download XLS</Trans>
                </Button>
              </Tooltip>
            </Can>
            {!ordered && (
              <Can I="update" a="Order">
                <Tooltip title={<Trans>Set order as ordered</Trans>}>
                  <Button color="primary" onClick={handleSetAsOrdered}>
                    <Trans>Set as ordered</Trans>
                  </Button>
                </Tooltip>
              </Can>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default compose(
  withStyles(detailCartFormStyle),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(DetailOrderForm);

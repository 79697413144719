import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_DISCOUNT_LIMIT_CODES_REQUEST,
  FETCH_DISCOUNT_LIMIT_CODES_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';

export function getDiscountLimitCodes() {
  return function (dispatch) {
    dispatch({type: FETCH_DISCOUNT_LIMIT_CODES_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.discountLimits)
      .then((response) => {
        dispatch({
          type: FETCH_DISCOUNT_LIMIT_CODES_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function saveDiscountLimitCodesChanges(data) {
  return function (dispatch) {
    let newData = [];
    Object.keys(data.items).forEach(function (key) {
      const value = data.items[key];

      newData.push({
        id: key,
        value: value,
        resource_uri: `/api/v1/discount-limits/${key}/`,
      });
    });

    return HttpClient.patch(API_ENDPOINT_MAP.discountLimits, {objects: newData}).catch((error) => {
      globalApiErrorHandler(error);
    });
  };
}

import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {getModules} from '_actions/projectSpecific/modulesActions';
import {usersPageStyle} from '_assets/projectSpecific/jss/views/usersPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import Tag from '_components/global/Tags/Tag';
import Loader from '_components/projectSpecific/Loader/Loader';
import {getOrderByV2, prepareSortingState} from '_helpers/functions';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {ViewModule} from '@material-ui/icons';

const ModulesPage = ({
  classes,
  getModules,
  globalSettings,
  updateGlobalSettings,
  modules,
  loading,
  total,
}) => {
  const [openDialog, setOpenDialog] = useState({data: null, open: false});
  const [order, setOrder] = useState({
    order: 'asc',
    orderBy: 'modulestore_id',
  });
  const [page, setPage] = useState(0);

  const rows = [
    {
      id: 'id',
      label: <Trans>Id</Trans>,
      sortable: true,
    },
    {
      id: 'modulestore_id',
      label: <Trans>Module store id</Trans>,
      sortable: true,
    },
    {
      id: 'is_demo',
      label: <Trans>Is demo</Trans>,
      sortable: true,
    },
    {
      id: 'validity_period_days',
      label: <Trans>Validity period</Trans>,
      sortable: true,
    },
    {
      id: 'kind',
      label: <Trans>Kind</Trans>,
      sortable: true,
    },
    {
      id: 'ordinality',
      label: <Trans>Ordinality</Trans>,
      sortable: true,
    },
    {
      id: 'is_orderable',
      label: <Trans>Is orderable</Trans>,
      sortable: true,
    },
    {
      id: 'permissions',
      label: <Trans>Permissions</Trans>,
      sortable: false,
    },
  ];

  const handleRequestSort = (event, property) => {
    setOrder(prepareSortingState(order.orderBy, order.order, property));
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    updateGlobalSettings({itemsPerPage: event.target.value});
    setPage(0);
  };

  const openPermissionDialog = (module) => {
    setOpenDialog({data: module, open: true});
  };

  const closePermissionDialog = () => {
    setOpenDialog({data: null, open: false});
  };

  const formatValidityPeriod = (validityPeriod) => {
    if (validityPeriod === 1) return <Trans>1 day</Trans>;
    if (validityPeriod > 1) return <Trans>{validityPeriod} days</Trans>;
    return '---';
  };

  const fetchModules = useCallback(() => {
    getModules(
      page * globalSettings.itemsPerPage,
      globalSettings.itemsPerPage,
      getOrderByV2(order.orderBy, order.order)
    );
  }, [getModules, page, order, globalSettings.itemsPerPage]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="blue" icon>
            <CardIcon color="blue">
              <ViewModule />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Trans>Modules</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.tableWrapper}>
              {loading && <Loader />}
              <Table className={classes.table} aria-labelledby="tableTitle">
                <SimpleTableHead
                  order={order.order}
                  orderBy={order.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={modules.length}
                  rows={rows}
                />
                <TableBody>
                  {modules.map((module, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell className={classes.cell}>{module.id}</TableCell>
                      <TableCell className={classes.cell}>{module.modulestore_id}</TableCell>
                      <TableCell className={classes.cell}>
                        {module.is_demo ? (
                          <div className={classes.successTextColor}>
                            <Trans>Yes</Trans>
                          </div>
                        ) : (
                          <div className={classes.dangerTextColor}>
                            <Trans>No</Trans>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {formatValidityPeriod(module.validity_period_days)}
                      </TableCell>
                      <TableCell className={classes.cell}>{module.kind}</TableCell>
                      <TableCell className={classes.cell}>{`${module.ordinality}.`}</TableCell>
                      <TableCell className={classes.cell}>
                        {module.is_orderable ? (
                          <div className={classes.successTextColor}>
                            <Trans>Yes</Trans>
                          </div>
                        ) : (
                          <div className={classes.dangerTextColor}>
                            <Trans>No</Trans>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {module.permissions && module.permissions.length ? (
                          <Button size="sm" onClick={() => openPermissionDialog(module)}>
                            <Trans>View permissions</Trans>
                          </Button>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Dialog
              open={openDialog.open}
              handleClose={closePermissionDialog}
              title={<Trans>Permissions of {openDialog.data?.modulestore_id}</Trans>}
              content={openDialog.data?.permissions?.map((permission) => (
                <Tag key={permission} content={permission} />
              ))}
            />
            <CustomTablePagination
              rowsPerPageOptions={globalSettings.rowsPerPageOptions}
              rowsPerPage={globalSettings.itemsPerPage}
              count={total}
              page={page}
              changePage={handleChangePage}
              changeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
      getModules,
    },
    dispatch
  );
};

export default compose(
  withStyles(usersPageStyle),
  connect((store) => {
    return {
      globalSettings: store.GlobalSettings,
      modules: store.Modules.modules,
      loading: store.Modules.loading,
      total: store.Modules.modulesTotal,
    };
  }, mapDispatchToProps)
)(ModulesPage);

import {userAccountFormStyle} from '_assets/projectSpecific/jss/components/Forms/userAccountFormStyle';
import {FormSwitcher, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {maxLength256, required, validatePasswordPolicies} from '_helpers/FieldLevelValidation';
import React, {useState} from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {Person, Visibility, VisibilityOff} from '@material-ui/icons';

const validateRepeatingPassword = (value, values) =>
  value !== values.main_user_password && <Trans>Passwords do not match!</Trans>;

const UserAccountForm = ({classes, handleSubmit, pristine, reset, submitting, form, authType}) => {
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  return (
    <form onSubmit={handleSubmit} className={classes.accountForm}>
      <GridContainer justify="center">
        <Can I="changeAuthType" a="Customers">
          <GridItem xs={12} md={9}>
            <Field
              name="main_user_auth_type"
              component={FormSwitcher}
              helpText={<Trans>Help text for setting auth type</Trans>}
              valueA="Local type"
              valueB="Oracle type"
            />
          </GridItem>
        </Can>
        <GridItem xs={12} md={9}>
          <Field
            name="main_user_username"
            label={<Trans>Login name</Trans>}
            id="main_user_username"
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="username">
                    <Person />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={[required, maxLength256]}
            required
          />
        </GridItem>
        {!authType ? (
          <>
            <GridItem xs={12} md={9}>
              <Field
                name="main_user_password"
                label={<Trans>Password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                id="main_user_password"
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
                validate={validatePasswordPolicies}
                required
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <Field
                name="repeatPassword"
                label={<Trans>Repeat password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
                validate={[required, validateRepeatingPassword]}
                required
              />
            </GridItem>
          </>
        ) : (
          ''
        )}
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(userAccountFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(UserAccountForm);

import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {getStammnummer} from '_actions/projectSpecific/vehicleReferencesActions';
import {vehicleReferencesPageStyle} from '_assets/projectSpecific/jss/views/vehicleReferencesPageStyle';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import Loader from '_components/projectSpecific/Loader/Loader';
import {VEHICLE_TYPES} from '_config/constants';
import {getComputedOrderBy, prepareSortingState} from '_helpers/functions';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const VRStamnummerTable = ({
  getStammnummer,
  classes,
  data,
  total,
  globalSettings,
  updateGlobalSettings,
  filters,
  loading,
  setShouldReload,
  shouldReload,
}) => {
  const [order, setOrder] = useState({
    order: 'asc',
    orderBy: 'number',
  });
  const [page, setPage] = useState(0);

  const rows = [
    {
      id: 'number',
      label: <Trans>Number</Trans>,
      sortable: true,
    },
    {
      id: 'k_type',
      label: <Trans>TecDoc Ktyp/Ntyp</Trans>,
      sortable: true,
    },
    {
      id: 'vehicle_type',
      label: <Trans>Type of vehicle</Trans>,
    },
  ];

  const handleRequestSort = (event, property) => {
    setOrder(prepareSortingState(order.orderBy, order.order, property));
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    updateGlobalSettings({itemsPerPage: event.target.value});
    setPage(0);
  };

  const fetchStammnummer = useCallback(() => {
    getStammnummer(
      page * globalSettings.itemsPerPage,
      globalSettings.itemsPerPage,
      getComputedOrderBy(order.orderBy, order.order),
      filters
    );
  }, [getStammnummer, page, order, filters, globalSettings.itemsPerPage]);

  useEffect(() => {
    fetchStammnummer();
  }, [fetchStammnummer]);

  useEffect(() => {
    if (shouldReload) {
      fetchStammnummer();
      setShouldReload((prevState) => ({...prevState, stammnummer: false}));
    }
  }, [shouldReload, fetchStammnummer, setShouldReload]);

  return (
    <>
      <div className={classes.tableWrapper}>
        {loading && <Loader />}
        <Table>
          <SimpleTableHead
            order={order.order}
            orderBy={order.orderBy}
            onRequestSort={handleRequestSort}
            rows={rows}
          />
          <TableBody>
            {data.map((row, index) => (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell className={classes.cell}>{row.number}</TableCell>
                <TableCell className={classes.cell}>{row.k_type}</TableCell>
                <TableCell className={classes.cell}>
                  {VEHICLE_TYPES.find((type) => type.value === row.vehicle_type).label}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <CustomTablePagination
        rowsPerPageOptions={globalSettings.rowsPerPageOptions}
        rowsPerPage={globalSettings.itemsPerPage}
        count={total}
        page={page}
        changePage={handleChangePage}
        changeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStammnummer,
      updateGlobalSettings,
    },
    dispatch
  );
};

export default compose(
  withStyles(vehicleReferencesPageStyle),
  connect((store) => {
    return {
      data: store.VehicleReferences.stamnummer.data,
      total: store.VehicleReferences.stamnummer.total,
      loading: store.VehicleReferences.stamnummer.loading,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(VRStamnummerTable);

import {discountGroupsListFormStyle} from '_assets/projectSpecific/jss/components/Forms/discountGroupsListFormStyle';
import {FormTextField} from '_components/global/Form/FormFields';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import {DiscountGroupsValidation} from '_helpers/FieldLevelValidation';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const DiscountLimitCodesListForm = ({
  classes,
  handleSubmit,
  pristine,
  reset,
  submitting,
  form,
  submitName,
  discountLimitCodes,
  handleRequestSort,
  rows,
  order,
  orderBy,
  inputLocked,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Table className={classes.table} aria-labelledby="tableTitle">
        <SimpleTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={discountLimitCodes.total}
          rows={rows}
        />
        <TableBody>
          {discountLimitCodes.data.map((discountLimit) => {
            const {code, discount_limits} = discountLimit;

            return (
              <TableRow hover tabIndex={-1} key={code}>
                <TableCell classes={{root: classes.cell}}>{code}</TableCell>
                {discount_limits && discount_limits.length
                  ? discount_limits.map((limit) => (
                      <TableCell key={limit.id} classes={{root: classes.cell}}>
                        <Field
                          name={`items[${limit.id}]`}
                          id={`items[${limit.id}]`}
                          component={FormTextField}
                          type="number"
                          disabled={inputLocked}
                          validate={DiscountGroupsValidation}
                        />
                      </TableCell>
                    ))
                  : ''}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </form>
  );
};

export default compose(
  withStyles(discountGroupsListFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(DiscountLimitCodesListForm);

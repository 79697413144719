import authorizedAbility from '_helpers/Authorization';
import {AbilityContext} from '_helpers/AuthorizedAbilityContext';
import App from 'App/App';
import store from 'App/store';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import MomentUtils from '@date-io/moment';

ReactDOM.render(
  <Provider store={store}>
    <AbilityContext.Provider value={authorizedAbility}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </AbilityContext.Provider>
  </Provider>,
  document.getElementById('root')
);

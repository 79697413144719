import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_AUTH} from '_actions/global/types';
import {FETCH_MY_CUSTOMER_REQUEST, FETCH_MY_CUSTOMER_SUCCESS} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';
import {SubmissionError} from 'redux-form';

export function editMyUserSettings(formParams) {
  return function (dispatch, getState) {
    return HttpClient.patch(`${API_ENDPOINT_MAP.me}`, {
      ...formParams,
    })
      .then((response) => {
        // const loggedUser = getState().Auth.logged
        const impersonatedUser = getState().Auth.impersonated;
        if (Object.keys(impersonatedUser).length !== 0) {
          dispatch({
            type: UPDATE_AUTH,
            data: {
              impersonated: response.data,
            },
          });
        } else {
          dispatch({
            type: UPDATE_AUTH,
            data: {
              logged: response.data,
            },
          });
        }
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getMyCustomerSettings() {
  return function (dispatch) {
    dispatch({type: FETCH_MY_CUSTOMER_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.myCustomer)
      .then((response) => {
        dispatch({
          type: FETCH_MY_CUSTOMER_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function editMyCustomerSettings(formParams) {
  return function () {
    return HttpClient.patch(`${API_ENDPOINT_MAP.myCustomer}`, {
      ...formParams,
    })
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function editLogoSettings(logo) {
  return function () {
    let formData = new FormData();
    if (logo) {
      formData.append('logo', logo, logo.name);
    }
    return HttpClient.post(`${API_ENDPOINT_MAP.logo}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function deleteAllGeneralFiles(filename) {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}delete-general?filename=${filename}`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function deleteAllPricelists() {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}delete-pricelists`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function deleteAllFlyers(filename) {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}delete-flyers/?filename=${filename}`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function copyPricelists() {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}copy-pricelists`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function copyGeneralFiles() {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}copy-general`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function copyFlyers() {
  return function () {
    return HttpClient.get(`${API_ENDPOINT_MAP.management}copy-flyers/`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

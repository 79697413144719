const TranslateAbilities = (permissions) => {
  let rules = [];
  permissions.forEach((permission) => {
    switch (permission.codename) {
      case 'view_customuser':
        rules.push({subject: 'Users', actions: 'read'});
        break;
      case 'add_customuser':
        rules.push({subject: 'Users', actions: 'create'});
        break;
      case 'change_customuser':
        rules.push({subject: 'Users', actions: 'update'});
        break;
      case 'activate_customuser':
        rules.push({subject: 'Users', actions: 'disable'});
        break;
      case 'impersonate_customuser':
        rules.push({subject: 'ImpersonateUser', actions: 'read'});
        break;
      case 'activate_customer':
        rules.push({subject: 'Customers', actions: 'activate'});
        break;
      case 'view_cart':
        rules.push({subject: 'Carts', actions: 'read'});
        break;
      case 'change_cart':
        rules.push({subject: 'Carts', actions: 'update'});
        break;
      case 'lock_cart':
        rules.push({subject: 'Carts', actions: 'lock'});
        break;
      case 'deliver_cart':
        rules.push({subject: 'Carts', actions: 'deliver'});
        break;
      case 'order_cart':
        rules.push({subject: 'Carts', actions: 'send'});
        break;
      case 'view_cartitem':
        rules.push({subject: 'CartItems', actions: 'read'});
        break;
      case 'view_customer':
        rules.push({subject: 'Customers', actions: 'read'});
        break;
      case 'add_customer':
        rules.push({subject: 'Customers', actions: 'create'});
        break;
      case 'change_customer':
        rules.push({subject: 'Customers', actions: 'update'});
        break;
      case 'delete_customer':
        rules.push({subject: 'Customers', actions: 'delete'});
        break;
      case 'impersonate_customer':
        rules.push({subject: 'ImpersonateCustomer', actions: 'read'});
        break;
      case 'view_permission':
        rules.push({subject: 'Permissions', actions: 'read'});
        break;
      case 'add_permission':
        rules.push({subject: 'Permissions', actions: 'create'});
        break;
      case 'change_permission':
        rules.push({subject: 'Permissions', actions: 'update'});
        break;
      case 'delete_permission':
        rules.push({subject: 'Permissions', actions: 'delete'});
        break;
      case 'change_customuser_permission_membership':
        rules.push({subject: 'UserPermissionMembership', actions: 'update'});
        break;
      case 'change_customer_permission_membership':
        rules.push({subject: 'CustomerPermissionMembership', actions: 'update'});
        break;
      case 'view_deliveryoption':
        rules.push({subject: 'DeliveryOptions', actions: 'read'});
        break;
      case 'add_deliveryoption':
        rules.push({subject: 'DeliveryOptions', actions: 'create'});
        break;
      case 'change_deliveryoption':
        rules.push({subject: 'DeliveryOptions', actions: 'update'});
        break;
      case 'delete_deliveryoption':
        rules.push({subject: 'DeliveryOptions', actions: 'delete'});
        break;
      case 'view_deliveryoption_membership':
        rules.push({subject: 'DeliveryOptionMembership', actions: 'read'});
        break;
      case 'change_deliveryoption_membership':
        rules.push({subject: 'DeliveryOptionMembership', actions: 'update'});
        break;
      case 'delete_deliveryoption_membership':
        rules.push({subject: 'DeliveryOptionMembership', actions: 'delete'});
        break;
      case 'view_lostsalereason':
        rules.push({subject: 'LostSaleReason', actions: 'read'});
        break;
      case 'view_lastlogin':
        rules.push({subject: 'LastLogin', actions: 'read'});
        break;
      case 'view_account':
        rules.push({subject: 'Accounts', actions: 'read'});
        break;
      case 'add_account':
        rules.push({subject: 'Accounts', actions: 'create'});
        break;
      case 'change_account':
        rules.push({subject: 'Accounts', actions: 'update'});
        break;
      case 'delete_account':
        rules.push({subject: 'Accounts', actions: 'delete'});
        break;
      case 'view_my_customuser':
        rules.push({subject: 'MyUser', actions: 'read'});
        break;
      case 'change_my_customuser':
        rules.push({subject: 'MyUser', actions: 'update'});
        break;
      case 'view_my_customer':
        rules.push({subject: 'MyCustomer', actions: 'read'});
        break;
      case 'change_my_customer':
        rules.push({subject: 'MyCustomer', actions: 'update'});
        break;
      case 'change_auth_type_customuser':
        rules.push({subject: 'Users', actions: 'changeAuthType'});
        break;
      case 'change_auth_type_customer':
        rules.push({subject: 'Customers', actions: 'changeAuthType'});
        break;
      case 'change_auth_type_account':
        rules.push({subject: 'Accounts', actions: 'changeAuthType'});
        break;
      case 'change_auth_type_my_customuser':
        rules.push({subject: 'MyUser', actions: 'changeAuthType'});
        break;
      case 'view_discountcategory':
        rules.push({subject: 'DiscountCategory', actions: 'read'});
        break;
      case 'add_discountgroup':
        rules.push({subject: 'DiscountGroup', actions: 'create'});
        break;
      case 'change_discountgroup':
        rules.push({subject: 'DiscountGroup', actions: 'update'});
        break;
      case 'delete_discountgroup':
        rules.push({subject: 'DiscountGroup', actions: 'delete'});
        break;
      case 'view_discountgroup':
        rules.push({subject: 'DiscountGroup', actions: 'read'});
        break;
      case 'view_discountlimitcode':
        rules.push({subject: 'DiscountLimitCode', actions: 'read'});
        break;
      case 'view_config':
        rules.push({subject: 'ApplicationSettings', actions: 'read'});
        break;
      case 'view_order':
        rules.push({subject: 'Order', action: 'read'});
        break;
      case 'change_order':
        rules.push({subject: 'Order', action: 'update'});
        break;
      case 'has_wholesaler_availability':
        rules.push({subject: 'Wholesaler', action: 'read'});
        break;
      case 'view_vehicle_references':
        rules.push({subject: 'VehicleReferences', actions: 'read'});
        break;
      case 'add_vehicle_references':
        rules.push({subject: 'VehicleReferences', actions: 'create'});
        break;
      case 'view_modules':
        rules.push({subject: 'Modules', actions: 'read'});
        break;
      default:
        break;
    }
  });
  return rules;
};

export default TranslateAbilities;

import SignInPage from 'App/views/global/auth/SignInPage';
import AccountsPage from 'App/views/projectSpecific/AccountsPage';
import Carts from 'App/views/projectSpecific/CartsPage';
import CompanySettingsPage from 'App/views/projectSpecific/CompanySettingsPage';
import CreateCustomerPage from 'App/views/projectSpecific/CreateCustomerPage';
import CreateUserPage from 'App/views/projectSpecific/CreateUserPage';
import CustomerDetailPage from 'App/views/projectSpecific/CustomerDetailPage';
import CustomersPage from 'App/views/projectSpecific/CustomersPage';
import DashboardPage from 'App/views/projectSpecific/DashboardPage';
import DeliveryOptionsPage from 'App/views/projectSpecific/DeliveryOptionsPage';
import DiscountGroupsPage from 'App/views/projectSpecific/DiscountGroupsPage';
import EditCustomerPage from 'App/views/projectSpecific/EditCustomerPage';
import EditUserPage from 'App/views/projectSpecific/EditUserPage';
import LastLoginPage from 'App/views/projectSpecific/LastLoginPage';
import LostSaleReasonPage from 'App/views/projectSpecific/LostSaleReasonPage';
import ModulesPage from 'App/views/projectSpecific/ModulesPage';
import OrdersPage from 'App/views/projectSpecific/OrdersPage';
import PermissionsPage from 'App/views/projectSpecific/PermissionsPage';
import UserProfilePage from 'App/views/projectSpecific/UserProfilePage';
import UsersPage from 'App/views/projectSpecific/UsersPage';
import VehicleReferencesPage from 'App/views/projectSpecific/VehicleReferencesPage';
import WholesalerAvailabilityPage from 'App/views/projectSpecific/WholesalerAvailabilityPage';
import React from 'react';
import {Trans} from '@lingui/macro';
import {
  AccountCircle,
  AccountTree,
  Dashboard,
  DirectionsCar,
  Edit,
  Group,
  GroupAdd,
  HowToReg,
  LocalShipping,
  Loyalty,
  Person,
  PersonAdd,
  RemoveShoppingCart,
  Settings,
  SettingsApplications,
  ShoppingCart,
  ViewModule,
  Visibility,
  VpnKey,
} from '@material-ui/icons';
import ApplicationSettingsPage from './views/projectSpecific/ApplicationSettingsPage';

const AuthRoutes = [
  {
    path: '/sign-in',
    name: <Trans>Sign in</Trans>,
    mini: 'L',
    visible: false,
    component: SignInPage,
    layout: '/auth',
  },
];

const AdminRoutes = [
  {
    path: '/dashboard',
    name: <Trans>Dashboard</Trans>,
    icon: Dashboard,
    visible: true,
    private: true,
    exact: true,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/users',
    name: <Trans>Users</Trans>,
    icon: Person,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Users', actions: 'read'},
    component: UsersPage,
    layout: '/admin',
  },
  {
    path: '/users/new',
    name: <Trans>Create user</Trans>,
    icon: PersonAdd,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Users', actions: 'create'},
    component: CreateUserPage,
    layout: '/admin',
  },
  {
    path: '/users/:id(\\d+)',
    name: <Trans>User profile</Trans>,
    icon: Person,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Users', actions: 'read'},
    component: UserProfilePage,
    layout: '/admin',
  },
  {
    path: '/users/:id(\\d+)/edit',
    name: <Trans>Edit user</Trans>,
    icon: Edit,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Users', actions: 'update'},
    component: EditUserPage,
    layout: '/admin',
  },
  {
    path: '/carts',
    name: <Trans>Shopping carts</Trans>,
    icon: ShoppingCart,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Carts', actions: 'read'},
    component: Carts,
    badge: {
      key: 'carts',
    },
    layout: '/admin',
  },
  {
    path: '/customers',
    name: <Trans>Customers</Trans>,
    icon: Group,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Customers', actions: 'read'},
    component: CustomersPage,
    layout: '/admin',
  },
  {
    path: '/customers/new',
    name: <Trans>Create customers</Trans>,
    icon: GroupAdd,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Customers', actions: 'create'},
    component: CreateCustomerPage,
    layout: '/admin',
  },
  {
    path: '/customers/:id(\\d+)',
    name: <Trans>Customer detail</Trans>,
    icon: Visibility,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Customers', actions: 'read'},
    component: CustomerDetailPage,
    layout: '/admin',
  },
  {
    path: '/customers/:id(\\d+)/edit',
    name: <Trans>Edit customers</Trans>,
    icon: Edit,
    visible: false,
    private: true,
    exact: true,
    authorized: {subject: 'Customers', actions: 'update'},
    component: EditCustomerPage,
    layout: '/admin',
  },
  {
    path: '/permissions',
    name: <Trans>Permissions</Trans>,
    icon: VpnKey,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Permissions', actions: 'read'},
    component: PermissionsPage,
    layout: '/admin',
  },
  {
    path: '/delivery-terms',
    name: <Trans>Delivery terms</Trans>,
    icon: LocalShipping,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'DeliveryOptionMembership', actions: 'read'},
    component: DeliveryOptionsPage,
    layout: '/admin',
  },
  {
    path: '/lost-sale-reason',
    name: <Trans>Lost sale reason</Trans>,
    icon: RemoveShoppingCart,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'LostSaleReason', actions: 'read'},
    component: LostSaleReasonPage,
    layout: '/admin',
  },
  {
    path: '/last-login',
    name: <Trans>Last login</Trans>,
    icon: HowToReg,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'LastLogin', actions: 'read'},
    component: LastLoginPage,
    layout: '/admin',
  },
  {
    path: '/accounts',
    name: <Trans>Accounts</Trans>,
    icon: AccountCircle,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Accounts', actions: 'read'},
    component: AccountsPage,
    layout: '/admin',
  },
  {
    path: '/company-settings',
    name: <Trans>Company settings</Trans>,
    icon: SettingsApplications,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'MyCustomer', actions: 'read'},
    component: CompanySettingsPage,
    layout: '/admin',
  },
  {
    path: '/discount-groups',
    name: <Trans>Discount Groups</Trans>,
    icon: Loyalty,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'DiscountCategory', actions: 'read'},
    component: DiscountGroupsPage,
    layout: '/admin',
  },
  {
    path: '/application-settings',
    name: <Trans>Application settings</Trans>,
    icon: Settings,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'ApplicationSettings', actions: 'read'},
    component: ApplicationSettingsPage,
    layout: '/admin',
  },
  {
    path: '/orders',
    name: <Trans>Orders</Trans>,
    icon: ShoppingCart,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Order', actions: 'read'},
    component: OrdersPage,
    layout: '/admin',
  },
  {
    path: '/wholesaler-availability',
    name: <Trans>Wholesaler availability</Trans>,
    icon: AccountTree,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Wholesaler', actions: 'read'},
    component: WholesalerAvailabilityPage,
    layout: '/admin',
  },
  {
    path: '/vehicle-references',
    name: <Trans>Vehicle references</Trans>,
    icon: DirectionsCar,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'VehicleReferences', actions: 'read'},
    component: VehicleReferencesPage,
    layout: '/admin',
  },
  {
    path: '/modules',
    name: <Trans>Modules</Trans>,
    icon: ViewModule,
    visible: true,
    private: true,
    exact: true,
    authorized: {subject: 'Modules', actions: 'read'},
    component: ModulesPage,
    layout: '/admin',
  },
];

export const AppRoutes = [...AuthRoutes, ...AdminRoutes];

import {
  FETCH_LICENCES_FAIL,
  FETCH_LICENCES_REQUEST,
  FETCH_LICENCES_SUCCESS,
  FETCH_MODULES_FAIL,
  FETCH_MODULES_REQUEST,
  FETCH_MODULES_SUCCESS,
} from '_actions/projectSpecific/types';

const initState = {
  modules: [],
  modulesLoading: false,
  modulesTotal: 0,
  licences: [],
  licencesLoading: false,
  licencesTotal: 0,
};

export const ModulesReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_MODULES_REQUEST: {
      return {...state, modulesLoading: true};
    }
    case FETCH_MODULES_SUCCESS: {
      return {
        ...state,
        modules: action.data,
        modulesTotal: action.total,
        modulesLoading: false,
      };
    }
    case FETCH_MODULES_FAIL: {
      return {
        ...state,
        modulesLoading: false,
      };
    }
    case FETCH_LICENCES_REQUEST: {
      return {
        ...state,
        licencesLoading: true,
      };
    }
    case FETCH_LICENCES_SUCCESS: {
      return {
        ...state,
        licences: action.data.objects,
        licencesTotal: action.total,
        licencesLoading: false,
      };
    }
    case FETCH_LICENCES_FAIL: {
      return {
        ...state,
        licencesLoading: false,
      };
    }
    default:
      return state;
  }
};

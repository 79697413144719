import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {
  changeCustomersInDiscountGroups,
  getCustomersInDiscountGroups,
  getCustomersInDiscountGroupsInsert,
} from '_actions/projectSpecific/customersActions';
import {getDiscountGroups} from '_actions/projectSpecific/discountGroupsActions';
import {customersInDiscountGroupsStyle} from '_assets/projectSpecific/jss/components/Tabs/customersInDiscountGroupsStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import CustomersToDiscountGroupForm from '_components/projectSpecific/Forms/CustomersToDiscountGroupForm';
import FilterCustomersInDiscountGroupsForm from '_components/projectSpecific/Forms/FilterCustomersInDiscountGroupsForm';
import CustomersInDiscountGroupsTableToolbar from '_components/projectSpecific/Toolbars/CustomersInDiscountGroupsTableToolbar';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/functions';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const rows = [
  {
    id: 'customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'name_1',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'address',
    label: <Trans>Address</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
      getCustomersInDiscountGroups,
      getDiscountGroups,
      getCustomersInDiscountGroupsInsert,
      changeCustomersInDiscountGroups,
    },
    dispatch
  );
};

class CustomersInDiscountGroupsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: this.props.CustomersInDiscountGroupsListFilter.orderBy,
      discountGroupId: this.props.CustomersInDiscountGroupsListFilter.discountGroupId,
      baseGroupId: null,
      openInsertCustomers: false,
      openDeleteCustomers: false,
      discountGroupName: '',
    };
  }

  componentDidMount = () => {
    this.fetchDiscountGroups().then(() => {
      this.filterDiscountGroups(this.props.filterFormData);
    });
  };

  fetchCustomersInDiscountGroups = () => {
    return this.props
      .getCustomersInDiscountGroups(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.discountGroupId
      )
      .then(() => {});
  };

  fetchCustomersInSelectedGroup = () => {
    return this.props.getCustomersInDiscountGroupsInsert(this.state.baseGroupId);
  };

  fetchDiscountGroups = () => {
    return this.props.getDiscountGroups(0, 0).then(() => {
      this.setState({
        baseGroupId:
          this.props.discountGroups.data.length && this.props.discountGroups?.data[0]?.id,
      });
    });
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchCustomersInDiscountGroups()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchCustomersInDiscountGroups());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchCustomersInDiscountGroups());
    });
  };

  filterDiscountGroups = (values) => {
    this.setState(
      {
        discountGroupId: values.discountGroupId,
      },
      () => this.fetchCustomersInDiscountGroups()
    );
  };

  handleResetDiscountGroupsFiltering = () => {
    this.setState(
      {
        discountGroupId: null,
      },
      () => this.fetchCustomersInDiscountGroups()
    );
  };

  getDiscountGroupsOptions = (data) => {
    const listOptions = [];

    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = `${obj.name && obj.name}`;
      listOptions.push(objectOption);
    });

    return listOptions;
  };

  getCustomersInDiscountGroupsFilterInit = () => {
    if (this.props.discountGroups.loading === false) {
      const storedValue = this.props.CustomersInDiscountGroupsListFilter.discountGroupId;
      const groups = this.props.discountGroups.data;
      if (storedValue !== null && groups.some((e) => e.id === parseInt(storedValue))) {
        return {
          discountGroupId: storedValue,
        };
      }
      if (groups.length && Array.isArray(groups)) {
        return {
          discountGroupId: groups[0]?.id,
        };
      }
    }
    return {};
  };

  handleOpenInsertCustomers = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(
      {
        openInsertCustomers: true,
      },
      () => this.fetchCustomersInSelectedGroup()
    );
  };

  handleCloseInsertCustomers = () => {
    this.setState({
      openInsertCustomers: false,
    });
  };

  handleInsertSelected = (values) => {
    const listCustomers = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    const listDiscountGroupIds = [];
    listDiscountGroupIds.push(this.state.discountGroupId);
    this.props.updateLoader({globalLoading: true});
    return this.props
      .changeCustomersInDiscountGroups(listCustomers, listDiscountGroupIds, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Customers were inserted.</Trans>);
        this.setState(
          {
            openInsertCustomers: false,
          },
          () => this.fetchCustomersInDiscountGroups()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.setState({
          openInsertCustomers: false,
        });
      });
  };

  handleOpenDeleteCustomers = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openDeleteCustomers: true,
    });
  };

  handleCloseDeleteCustomers = () => {
    this.setState({
      openDeleteCustomers: false,
    });
  };

  deleteCustomersFromDiscountGroup = (values) => {
    const listCustomers = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    const listDiscountGroupIds = [];
    listDiscountGroupIds.push(this.state.discountGroupId);
    this.props.updateLoader({globalLoading: true});
    return this.props
      .changeCustomersInDiscountGroups(listCustomers, listDiscountGroupIds, true)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Customers were removed.</Trans>);
        this.setState(
          {
            openDeleteCustomers: false,
          },
          () => this.fetchCustomersInDiscountGroups()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.setState({
          openDeleteCustomers: false,
        });
      });
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card chart className={classes.filterCard}>
            <CardBody>
              <FilterCustomersInDiscountGroupsForm
                form="filterDiscountGroupsForCustomersForm"
                initialValues={this.getCustomersInDiscountGroupsFilterInit()}
                discountGroupsOptions={this.getDiscountGroupsOptions(
                  this.props.discountGroups.data
                )}
                loading={this.props.discountGroups.loading}
                onSubmit={this.filterDiscountGroups}
                handleReset={this.handleResetDiscountGroupsFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Can I="update" a="Customers">
            <CustomersInDiscountGroupsTableToolbar
              openInsertCustomers={this.handleOpenInsertCustomers}
              openDeleteCustomers={this.handleOpenDeleteCustomers}
              deleteDisabled={this.props.customersInDiscountGroups.total === 0}
              disabled={
                this.props.discountGroups.loading ||
                this.props.discountGroups.data[0]?.id === this.state.discountGroupId
              }
            />
          </Can>

          <Dialog
            open={this.state.openInsertCustomers}
            handleClose={this.handleCloseInsertCustomers}
            title={<Trans>Insert customers into Discount Group.</Trans>}
            maxWidth={false}
            content={
              <CustomersToDiscountGroupForm
                form="insertCustomersInDiscountGroup"
                customers={this.props.customersInDiscountGroupsInsert}
                rows2={rows}
                onSubmit={(values) => {
                  return this.handleInsertSelected(values);
                }}
              />
            }
          />
          <Dialog
            open={this.state.openDeleteCustomers}
            handleClose={this.handleCloseDeleteCustomers}
            title={<Trans>Delete customers from Discount group.</Trans>}
            maxWidth={false}
            content={
              <CustomersToDiscountGroupForm
                form="DeleteCustomersFromDiscountGroup"
                customers={this.props.customersInDiscountGroups}
                rows2={rows}
                onSubmit={(values) => {
                  return this.deleteCustomersFromDiscountGroup(values);
                }}
              />
            }
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <SimpleTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.customersInDiscountGroups.total}
                rows={rows}
              />
              <TableBody>
                {this.props.customersInDiscountGroups.data.map((customer) => {
                  const {id, customer_no, name, address_1, address_2, address_3, post_code, city} =
                    customer;

                  return (
                    <TableRow hover tabIndex={-1} key={id}>
                      <TableCell classes={{root: classes.cell}}>{customer_no}</TableCell>
                      <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                      <TableCell classes={{root: classes.cell}}>
                        {`${address_1 ? address_1 : ''} ${address_2 ? address_2 : ''} ${
                          address_3 ? address_3 : ''
                        } ${post_code ? post_code : ''} ${city ? city : ''}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <CustomTablePagination
            rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
            count={this.props.customersInDiscountGroups.total}
            rowsPerPage={this.props.globalSettings.itemsPerPage}
            page={page}
            changePage={this.handleChangePage}
            changeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(customersInDiscountGroupsStyle),
  connect((store) => {
    return {
      CustomersInDiscountGroupsListFilter:
        store.AppFilterStates.customersInDiscountGroupsListFilter,
      globalSettings: store.GlobalSettings,
      customersInDiscountGroups: store.CustomersInDiscountGroups,
      filterFormData: getFormValues('filterDiscountGroupsForCustomersForm')(store),
      discountGroups: store.DiscountGroups,
      customersInDiscountGroupsInsert: store.CustomersInDiscountGroupsInsert,
    };
  }, mapDispatchToProps)
)(CustomersInDiscountGroupsTab);

import {updateLoader} from '_actions/global/loaderActions';
import {getBranches} from '_actions/projectSpecific/branchesActions';
import {getGroups} from '_actions/projectSpecific/groupsActions';
import {addUserPermission, getPermissions} from '_actions/projectSpecific/permissionsActions';
import {editUser, getUser} from '_actions/projectSpecific/usersActions';
import {editUserPageStyle} from '_assets/projectSpecific/jss/views/editUserPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import PermissionsForm from '_components/projectSpecific/Forms/PermissionsForm';
import UserForm from '_components/projectSpecific/Forms/UserForm';
import CommonTabs from '_components/projectSpecific/Tabs/CommonTabs';
import authorizedAbility from '_helpers/Authorization';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {fireErrorToast, fireSuccessToast} from '_helpers/functions';
import {browserHistoryAccessor} from 'App/history';
import {diff} from 'deep-object-diff';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {ArrowBack, Edit} from '@material-ui/icons';

const rows2 = [
  {
    id: 'empty',
    label: '',
    sortable: false,
  },
  {
    id: 'code',
    label: <Trans>Code</Trans>,
    sortable: false,
  },
  {
    id: 'Name',
    label: <Trans>Name</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editUser,
      getUser,
      updateLoader,
      addUserPermission,
      getPermissions,
      getBranches,
      getGroups,
    },
    dispatch
  );
};

class EditUserPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      initPermissions: false,
    };
    if (authorizedAbility.cannot('update', 'Users')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchUsers();
    this.fetchBranches();
    this.fetchGroups();
  };

  fetchUsers = () => {
    const {id} = this.props.match.params;
    this.props.getUser(id);
  };

  fetchPermission = () => {
    return this.props.getPermissions(0, 0);
  };

  fetchBranches = () => {
    this.props.getBranches();
  };

  fetchGroups = () => {
    this.props.getGroups();
  };

  updateUser = (values, user) => {
    delete values.repeatPassword;
    const editedUser = diff(user, values);
    if (editedUser.branch) {
      editedUser.branch = {
        pk: editedUser.branch,
      };
    }

    if (editedUser.groups) {
      if (editedUser.groups === 'empty') {
        editedUser.groups = null;
      } else {
        editedUser.groups = [{pk: editedUser.groups}];
      }
    }

    if (editedUser.auth_type === true) {
      editedUser.auth_type = 'oracle';
    } else {
      editedUser.auth_type = 'local';
    }
    this.props.updateLoader({globalLoading: true});
    const {id} = this.props.match.params;
    return this.props
      .editUser(id, editedUser)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>User: {values.username} was successfully edited</Trans>
        );
        browserHistoryAccessor.push('/admin/users');
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleUserTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleUserTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  handleUserPermissionsTabClick = (event) => {
    event.preventDefault();
    !this.state.initPermissions &&
      this.setState(
        {
          initPermissions: true,
        },
        () => {
          this.fetchPermission();
        }
      );
  };

  getOptions = (data) => {
    const listOptions = [];
    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = obj.name;
      listOptions.push(objectOption);
    });
    listOptions.unshift({value: 'empty', label: '----------'});
    return listOptions;
  };

  getUserPermissionsInit = (permissions) => {
    const initValues = {};
    initValues.options = {};

    permissions.forEach((item) => {
      initValues.options[item.id] = true;
    });

    return initValues;
  };

  getInitUserValues = (user) => {
    const initValues = {...user};
    if (user.branch) {
      initValues.branch = user.branch.id;
    }
    if (user.groups.length && Array.isArray(user.groups)) {
      initValues.groups = user.groups[0].id;
    } else {
      initValues.groups = 'empty';
    }

    if (user.auth_type === 'oracle') {
      initValues.auth_type = true;
    } else {
      initValues.auth_type = false;
    }
    return initValues;
  };

  editUserPermissions = (id, values) => {
    const listIds = [];
    listIds.push(id);
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addUserPermission(listIds, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permissions were added/edited.</Trans>);
        this.fetchUsers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  prepareTabs = () => {
    const tabs = [<Tab key="edit" label={<Trans>Edit user</Trans>} />];
    if (authorizedAbility.can('update', 'UserPermissionMembership')) {
      tabs.push(
        <Tab
          key="permissions"
          label={<Trans>Permissions</Trans>}
          onClick={this.handleUserPermissionsTabClick}
        />
      );
    }
    return tabs;
  };

  render() {
    const {classes, theme} = this.props;
    const user = this.props.user;
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          {!this.props.isUserLoading && (
            <Card>
              <CardHeader color="blue" icon className={classes.root}>
                <CardIcon color="blue">
                  <Edit />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <Trans>Edit</Trans> <b>{user.username}</b>
                </h4>
                <div className={classes.backBtn}>
                  <Tooltip title={<Trans>Back to list</Trans>}>
                    <Button className={classes.btnIcon} component={Link} to="/admin/users">
                      <ArrowBack />
                    </Button>
                  </Tooltip>
                </div>
                <div className={classes.tabBar}>
                  <AppBar position="static" color="default">
                    <CommonTabs value={this.state.tabValue} onChange={this.handleUserTabChange}>
                      {this.prepareTabs()}
                    </CommonTabs>
                  </AppBar>
                </div>
              </CardHeader>
              <CardBody>
                <SwipeableView
                  index={this.state.tabValue}
                  onChangeIndex={this.handleUserTabChangeIndex}
                >
                  <TabContainer dir={theme.direction}>
                    <div className={classes.step}>
                      <UserForm
                        initialValues={this.getInitUserValues(user)}
                        form="editUser"
                        onSubmit={(values) => {
                          return this.updateUser(values, user);
                        }}
                        submitName={<Trans>Edit user</Trans>}
                        userId={user.id}
                        customerNo={user.customer && user.customer.customer_no}
                        branchesOptions={this.getOptions(this.props.branches)}
                        roleOptions={this.getOptions(this.props.groups)}
                        authType={this.props.authType && this.props.authType}
                      />
                    </div>
                  </TabContainer>
                  <TabContainer dir={theme.direction}>
                    <Can I="update" a="UserPermissionMembership">
                      {this.props.permissions.length > 0 && (
                        <PermissionsForm
                          initialValues={this.getUserPermissionsInit(user.permissions)}
                          form="editUserPermissions"
                          rows2={rows2}
                          options={this.props.permissions}
                          onSubmit={(values) => {
                            return this.editUserPermissions(user.id, values);
                          }}
                        />
                      )}
                    </Can>
                  </TabContainer>
                </SwipeableView>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('editUser');

export default compose(
  withStyles(editUserPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      user: store.User.data,
      isUserLoading: store.User.loading,
      permissions: store.Permissions.data,
      branches: store.Branches.data,
      groups: store.Groups.data,
      authType: selector(store, 'auth_type'),
    };
  }, mapDispatchToProps)
)(EditUserPage);

import {
  blueColor20,
  blueColor80,
  cardTitle,
  dangerColor,
  successColor,
} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core/styles/index';

export const usersPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
    position: 'relative',
  },
  activeIcon: {
    fontSize: '10px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  customLink: {
    color: blueColor80,
    textDecoration: 'none',
    '&:hover': {
      color: blueColor20,
    },
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  paper: {
    margin: '10px 0px',
    padding: '10px',
  },
  successTextColor: {
    color: successColor[0],
    fontWeight: 500,
  },
  dangerTextColor: {
    color: dangerColor[1],
    fontWeight: 500,
  },
  demoChip: {
    marginLeft: '10px',
    padding: '4px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    backgroundColor: blueColor20,
    color: 'white',
    height: '20px',
    fontSize: '12px',
    '& > span': {
      padding: 0,
    },
  },
});

export const iconTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: '#76ff03',
    },
  },
});

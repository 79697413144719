import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_PERMISSIONS_FAIL,
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';
import {SubmissionError} from 'redux-form';

export function getPermissions(offset = 0, limit = 20, orderBy = null) {
  return function (dispatch) {
    dispatch({type: FETCH_PERMISSIONS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.permissions, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_PERMISSIONS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        dispatch({type: FETCH_PERMISSIONS_FAIL});
        globalApiErrorHandler(error);
      });
  };
}

export function addCustomerPermissions(customer_ids, permission_ids, isSet, isDelete) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.customerPermissionMembership, {
      customer_ids: customer_ids,
      permission_ids: permission_ids,
      is_set: isSet,
      is_delete: isDelete,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function addCustomerAccessiblePermissions(
  customerIds,
  permissionIds,
  isSet = true,
  isDelete = false
) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.customerAccessiblePermissions, {
      customer_ids: customerIds,
      permission_ids: permissionIds,
      is_set: isSet,
      is_delete: isDelete,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function insertPermission(formParams) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.permissions, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function editPermission(permission_id, formParams) {
  return function () {
    return HttpClient.patch(`${API_ENDPOINT_MAP.permissions}${permission_id}/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function deletePermission(permission_id) {
  return function () {
    return HttpClient.del(`${API_ENDPOINT_MAP.permissions}${permission_id}/`)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function addUserPermission(userIds, permissionIds, isSet, isDelete) {
  return function () {
    return HttpClient.post(`${API_ENDPOINT_MAP.userPermissionMembership}`, {
      user_ids: userIds,
      permission_ids: permissionIds,
      is_set: isSet,
      is_delete: isDelete,
    })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        throw new Error(error.response.data.error_message);
      });
  };
}

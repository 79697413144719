import {updateLoader} from '_actions/global/loaderActions';
import {editConfig, getConfig} from '_actions/projectSpecific/configActions';
import ConfigurationForm from '_components/projectSpecific/Configuration/ConfigurationForm';
import {fireErrorToast, fireSuccessToast} from '_helpers/functions';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const ConfigurationTab = (props) => {
  const {configData, editConfig, getConfig, updateLoader} = props;

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const handleSubmit = (values) => {
    if (configData) {
      updateLoader({globalLoading: true});
      editConfig(values.field)
        .then((res) => {
          updateLoader({globalLoading: false});
          fireSuccessToast(<Trans>Configuration successfully updated.</Trans>);
          getConfig();
        })
        .catch((error) => {
          fireErrorToast(error?.errors?._error, {autoClose: false});
          updateLoader({globalLoading: false});
        });
    }
  };

  const getInitialValues = (configData) => {
    let initVal = {field: {}};

    configData.forEach((config) => {
      initVal.field[config.key] = config['value'];
    });
    return initVal;
  };

  return (
    <div>
      <ConfigurationForm
        form="configuration"
        onSubmit={handleSubmit}
        submitName={<Trans>Update configuration</Trans>}
        data={configData}
        initialValues={configData && getInitialValues(configData)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getConfig,
      editConfig,
    },
    dispatch
  );
};

export default compose(
  withStyles({withTheme: true}),
  connect((store) => {
    return {
      configData: store.Config.data,
    };
  }, mapDispatchToProps)
)(ConfigurationTab);

import {grayColor80} from '_assets/global/jss/styleHelpers';

const DialogTitleStyle = (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: grayColor80,
    marginLeft: '10px',
  },
});

export default DialogTitleStyle;

import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import {Add, VpnKey} from '@material-ui/icons';

const PermissionsTableToolbar = ({classes, handlePermissionInsert}) => {
  return (
    <Can I="create" a="Permissions">
      <Toolbar className={cx(classes.root)}>
        <Tooltip title={<Trans>Insert permission</Trans>}>
          <Button aria-label="InsertNew" onClick={() => handlePermissionInsert()}>
            <Add />
            <VpnKey />
          </Button>
        </Tooltip>
      </Toolbar>
    </Can>
  );
};

PermissionsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonTableToolbarStyle)(PermissionsTableToolbar);

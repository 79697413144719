import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_WHOLESALER_HISTORY_REQUEST,
  FETCH_WHOLESALER_HISTORY_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';
import {SubmissionError} from 'redux-form';

export function postWholesalerAvailability(file) {
  return function (dispatch) {
    let formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    return HttpClient.post(API_ENDPOINT_MAP.wholesalerAvailability + 'import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        } else {
          return Promise.reject(error);
        }
      });
  };
}

export function getWholesalerAvailabilityHistory(offset = 0, limit = 20, orderBy = null) {
  return function (dispatch) {
    const wholesalerAvailabilityFilters = {
      wholesalerAvailabilityFilter: {
        orderBy,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: wholesalerAvailabilityFilters,
    });

    dispatch({type: FETCH_WHOLESALER_HISTORY_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.wholesalerAvailability + 'history', {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_WHOLESALER_HISTORY_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        } else {
          return Promise.reject(error);
        }
      });
  };
}

export function getWholesalerAvailabilityHistoryDownload(id) {
  return function (dispatch) {
    return HttpClient.get(API_ENDPOINT_MAP.wholesalerAvailability + 'history/' + id + '/download')
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function postWholesalerAvailabilityChange(data) {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.wholesalerAvailability + 'change', data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        } else {
          return Promise.reject(error);
        }
      });
  };
}

export function getWholesalerAvailabilityTemplateFile() {
  return function (dispatch) {
    return HttpClient.get(API_ENDPOINT_MAP.wholesalerAvailability + 'download-template-file')
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

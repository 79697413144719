import usersTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/usersTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import {PersonAdd} from '@material-ui/icons';

class UsersTableToolbar extends Component {
  render() {
    const {classes} = this.props;

    const Actions = (
      <Can I="create" a="Users">
        <div className={classes.flex}>
          <Tooltip title={<Trans>Insert user</Trans>}>
            <Button aria-label="InsertNew" component={Link} to="/admin/users/new">
              <PersonAdd />
            </Button>
          </Tooltip>
        </div>
      </Can>
    );

    return (
      <div>
        {/*view for Mobile*/}
        <Hidden smUp implementation="css">
          <Toolbar className={cx(classes.root)}>
            <div className={classes.title}>
              <div>{Actions}</div>
            </div>
          </Toolbar>
        </Hidden>

        {/*view for Desktop*/}
        <Hidden smDown implementation="css">
          <Toolbar className={cx(classes.root)}>
            <div className={classes.spacer} />
            <div className={classes.actions}>{Actions}</div>
          </Toolbar>
        </Hidden>
      </div>
    );
  }
}

UsersTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(usersTableToolbarStyle)(UsersTableToolbar);

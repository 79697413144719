import React from 'react';
import {Trans} from '@lingui/macro';

export const VEHICLE_TYPES = [
  {value: 10, label: <Trans>Personal car (PC)</Trans>},
  {value: 20, label: <Trans>Light commercial vehicle (LCV)</Trans>},
  {value: 60, label: <Trans>Commercial vehicle (CV)</Trans>},
  {value: 99, label: <Trans>Motorcycle (MC)</Trans>},
];

export const LICENCE_STAUSES = [
  {
    value: 'PLANNED',
    label: <Trans>Planned</Trans>,
  },
  {
    value: 'ACTIVE',
    label: <Trans>Active</Trans>,
  },
  {
    value: 'TO_BE_CANCELED',
    label: <Trans>To be canceled</Trans>,
  },
  {
    value: 'TRIALING',
    label: <Trans>Trialing</Trans>,
  },
  {
    value: 'FINISHED',
    label: <Trans>Finished</Trans>,
  },
];

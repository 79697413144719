import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_WAITING_FAIL,
  FETCH_WAITING_REQUEST,
  FETCH_WAITING_SUCCESS,
  POST_WAITING_FAIL,
  POST_WAITING_REQUEST,
  POST_WAITING_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';
import React from 'react';
import {SubmissionError} from 'redux-form';
import {Trans} from '@lingui/macro';

export function getConfig() {
  return function (dispatch) {
    dispatch({type: FETCH_CONFIG_REQUEST});

    return HttpClient.get(API_ENDPOINT_MAP.config)
      .then((res) => {
        dispatch({
          type: FETCH_CONFIG_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        globalApiErrorHandler(err);
      });
  };
}

export function editConfig(data) {
  return function (dispatch) {
    return HttpClient.put(API_ENDPOINT_MAP.config, data)
      .then((res) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message || <Trans>Something went wrong</Trans>,
          });
        }
        return Promise.reject();
      });
  };
}

export function getWaitingOrders() {
  return function (dispatch) {
    dispatch({type: FETCH_WAITING_REQUEST});
    return HttpClient.get(`${API_ENDPOINT_MAP.management}send-waiting-orders/`)
      .then((response) => {
        dispatch({type: FETCH_WAITING_SUCCESS, data: response.data});
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch({type: FETCH_WAITING_FAIL});
        globalApiErrorHandler(error);
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
        return Promise.reject(error);
      });
  };
}

export function postWaitingOrders(data = {}) {
  return function (dispatch) {
    dispatch({type: POST_WAITING_REQUEST});
    return HttpClient.post(`${API_ENDPOINT_MAP.management}send-waiting-orders/`, data)
      .then((response) => {
        dispatch({type: POST_WAITING_SUCCESS});
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch({type: POST_WAITING_FAIL});
        globalApiErrorHandler(error);
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
        return Promise.reject(error);
      });
  };
}

import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';

const DiscountGroupsTableToolbar = (props) => {
  const fileInput = useRef(null);

  const {
    classes,
    handleAdd,
    handleEdit,
    handleSave,
    inputLocked,
    openDeleteDiscountGroups,
    handleImport,
    handleExport,
    updateLoader,
  } = props;

  const handleImportClick = () => {
    fileInput.current.click();
  };

  const handleChangeOnImport = (e) => {
    updateLoader({localLoading: true});
    let reader = new FileReader();
    let file = e.target.files[0];

    file && reader.readAsDataURL(file);
    reader.onloadend = () => {
      updateLoader({localLoading: false});
      handleImport(file);
    };
  };

  return (
    <Toolbar className={cx(classes.root)}>
      <Can I="create" a="DiscountGroup">
        <Tooltip title={<Trans>Add discount group</Trans>}>
          <Button onClick={handleAdd}>
            <Trans>Add</Trans>
          </Button>
        </Tooltip>
      </Can>
      <Can I="update" a="DiscountGroup">
        <Tooltip title={<Trans>Import discount group</Trans>}>
          <Button onClick={handleImportClick}>
            <Trans>Import</Trans>
          </Button>
        </Tooltip>
        <input
          style={{display: 'none'}}
          onChange={handleChangeOnImport}
          onClick={(e) => (e.target.value = null)}
          ref={fileInput}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Can>
      <Can I="read" a="DiscountGroup">
        <Tooltip title={<Trans>Export discount group</Trans>}>
          <Button onClick={handleExport}>
            <Trans>Export</Trans>
          </Button>
        </Tooltip>
      </Can>
      <Can I="update" a="DiscountGroup">
        {inputLocked ? (
          <Tooltip title={<Trans>Edit discount group</Trans>}>
            <Button onClick={handleEdit}>
              <Trans>Edit</Trans>
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={<Trans>Save discount group</Trans>}>
            <Button onClick={handleSave} color="primary">
              <SaveIcon /> <Trans>Save</Trans>
            </Button>
          </Tooltip>
        )}
      </Can>
      <Can I="delete" a="DiscountGroup">
        <Tooltip title={<Trans>Delete discount group</Trans>}>
          <Button onClick={openDeleteDiscountGroups}>
            <Trans>Delete</Trans>
          </Button>
        </Tooltip>
      </Can>
    </Toolbar>
  );
};

DiscountGroupsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAdd: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  handleImport: PropTypes.func,
  handleExport: PropTypes.func,
  updateLoader: PropTypes.func,
};

export default withStyles(commonTableToolbarStyle)(DiscountGroupsTableToolbar);

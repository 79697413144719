import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {discountGroupsPageStyle} from '_assets/projectSpecific/jss/views/discountGroupsPageStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import CommonTabs from '_components/projectSpecific/Tabs/CommonTabs';
import CustomersInDiscountGroupsTab from '_components/projectSpecific/Tabs/CustomersInDiscountGroupsTab';
import DiscountGroupsTab from '_components/projectSpecific/Tabs/DiscountGroupsTab';
import DiscountLimitCodesTab from '_components/projectSpecific/Tabs/DiscountLimitCodesTab';
import authorizedAbility from '_helpers/Authorization';
import {Can} from '_helpers/AuthorizedAbilityContext';
import {browserHistoryAccessor} from 'App/history';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tab from '@material-ui/core/Tab';
import {Loyalty} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
    },
    dispatch
  );
};

class DiscountGroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      initCustomersInDiscountGroups: false,
      initDiscountLimitCodes: false,
    };
    if (authorizedAbility.cannot('read', 'DiscountGroup')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  handleCustomersInDiscountGroupsTabClick = (event) => {
    event.preventDefault();
    !this.state.initCustomersInDiscountGroups &&
      this.setState({
        initCustomersInDiscountGroups: true,
      });
  };

  handleDiscountLimitCodesTabClick = (event) => {
    event.preventDefault();
    !this.state.initDiscountLimitCodes &&
      this.setState({
        initDiscountLimitCodes: true,
      });
  };

  render() {
    const {classes, theme} = this.props;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <Loyalty />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Discount groups</Trans>
              </h4>
              <div className={classes.tabBar}>
                <AppBar position="static" color="default">
                  <CommonTabs value={this.state.tabValue} onChange={this.handleTabChange}>
                    <Tab label={<Trans>Discount groups</Trans>} />
                    <Tab
                      label={<Trans>Customers in discount groups</Trans>}
                      onClick={this.handleCustomersInDiscountGroupsTabClick}
                    />
                    <Tab
                      label={<Trans>Discount limit codes</Trans>}
                      onClick={this.handleDiscountLimitCodesTabClick}
                    />
                  </CommonTabs>
                </AppBar>
              </div>
            </CardHeader>
            <CardBody>
              <SwipeableView index={this.state.tabValue} onChangeIndex={this.handleTabChangeIndex}>
                <Can I="read" a="DiscountCategory">
                  <TabContainer dir={theme.direction}>
                    <DiscountGroupsTab />
                  </TabContainer>
                </Can>
                <Can I="read" a="DiscountGroup">
                  <TabContainer dir={theme.direction}>
                    {this.state.initCustomersInDiscountGroups === true ? (
                      <CustomersInDiscountGroupsTab />
                    ) : (
                      <CircularProgress />
                    )}
                  </TabContainer>
                </Can>
                <Can I="read" a="DiscountLimitCode">
                  <TabContainer dir={theme.direction}>
                    {this.state.initDiscountLimitCodes === true ? (
                      <DiscountLimitCodesTab />
                    ) : (
                      <CircularProgress />
                    )}
                  </TabContainer>
                </Can>
              </SwipeableView>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(discountGroupsPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(DiscountGroupsPage);

export const API_PREFIX = '/api/v1';
export const API_PREFIX_V2 = '/api/v2';
export const EC24_API_PREFIX = '/ec24/v1';
// change API_PREFIX to '/api/v1/', when not using the mock server
// export const API_PREFIX = '';
export const FORCED_API_SERVER = 'https://disadmin-be-test.ec24.ch';
export const RECAPTCHA_KEY_DEFAULT = '6LdgGRcqAAAAAOXXM8t6XTpqmYaeq21k9fPVU9cv';
// export const FORCED_API_SERVER = null;
export const DESKTOP_BREAKPOINT = 960;

export const DEV_REFRESH_NOTIFICATIONS = false;
export const ENVIRONMENT = process.env.NODE_ENV;

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_DB = 'YYYY-MM-DD';

export const AVAILABLE_LANGUAGES = ['en', 'fr', 'de', 'it'];
export const LANGUAGES = [
  {value: 'en', label: 'English'},
  {value: 'fr', label: 'Français'},
  {value: 'de', label: 'Deutsch'},
  {value: 'it', label: 'Italiano'},
];

export const STATUS_1 = [
  {value: 'new', label: 'New'},
  {value: 'partly_done', label: 'Partly done'},
  {value: 'closed', label: 'Closed'},
];

export const STATUS_2 = [
  {value: 'all', label: 'All'},
  {value: 'new,partly_done', label: 'New and Partly done'},
  {value: 'new', label: 'New'},
  {value: 'partly_done', label: 'Partly done'},
  {value: 'closed', label: 'Closed'},
];

export const PHONE_PREFIX = [
  {value: 'CH', label: '(CH) +41'},
  {value: 'CZ', label: '(CZ) +420'},
  {value: 'DE', label: '(DE) +49'},
  {value: 'FR', label: '(FR) +33'},
  {value: 'HU', label: '(HU) +36'},
  {value: 'IT', label: '(IT) +39'},
  {value: 'PL', label: '(PL) +48'},
  {value: 'RO', label: '(RO) +40'},
];

export const FORMAT_EXTENSION_MAP = {
  'text/csv': 'csv',
  'text/plain': 'txt',
};

export default {
  LANGUAGES,
  STATUS_1,
  STATUS_2,
  PHONE_PREFIX,
};

import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_LOST_SALE_REASON_REQUEST,
  FETCH_LOST_SALE_REASON_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/functions';

export function getLostSaleReason(
  offset = 0,
  limit = 20,
  orderBy = null,
  username = null,
  productNo = null
) {
  return function (dispatch) {
    const lostSaleReasonFilter = {
      lostSaleReasonListFilter: {
        orderBy,
        username,
        productNo,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: lostSaleReasonFilter,
    });
    dispatch({type: FETCH_LOST_SALE_REASON_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.lostSaleReason, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      username__startwith: username ? username : null,
      product_no__startwith: productNo ? productNo : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_LOST_SALE_REASON_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}
